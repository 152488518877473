import {
  IonBadge,
  IonButton,
  IonCol,
  IonContent,
  IonFab,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonText,
  RefresherEventDetail,
  useIonModal,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { User } from "firebase/auth";
import { heart, heartOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase";

import {
  useQueryNextPageQuery,
  useQueryPublicTilesQuery,
} from "../../../store/api/baseApi";
import {
  useAddFavoriteMutation,
  useRemoveFavoriteMutation,
} from "../../../store/api/tilesApi";
import { Tile } from "../../../types/tiles";
import TileModal from "../../Modals/TileModal/TileModal";
import "./PublicTileTable.scss";

import { isPlatform } from "@ionic/react";

/**
 * A table of tiles.
 *
 * @param param0 {name: string, sub: string, tiles: Tile[]}
 *
 */
const PublicTileTable: React.FC = () => {
  const isMobile =
    (isPlatform("mobile") || isPlatform("mobileweb")) && !isPlatform("ipad");

  const [selectedTile, setSelectedTile] = useState<Tile>();
  const [tileColumns, setTileColumns] = useState<Tile[][]>([]);
  const [currentUser, setCurrentUser] = useState<User | null>();

  const [user] = useAuthState(auth, {
    onUserChanged: (user) => handleUserChange(user),
  });

  const { data, refetch } = useQueryPublicTilesQuery({
    resLimit: 20,
    currentUser: currentUser || user,
  });

  // useQueryNextPageQuery with skip true to avoid initial load
  const { refetch: nextPage } = useQueryNextPageQuery(undefined, {
    skip: true,
  });

  const [addFavorite] = useAddFavoriteMutation();
  const [removeFavorite] = useRemoveFavoriteMutation();

  // unmount the component when leaving
  useEffect(() => {
    return () => {
      setTileColumns([]);
    };
  }, []);

  // hand user state change
  const handleUserChange = async (userObj: User | null) => {
    setCurrentUser(userObj);
  };

  // set tile columns into two columns for every other tile
  const splitTilesInHalf = (tiles: Tile[]) => {
    if (isMobile) {
      setTileColumns([tiles]);
    } else {
      const secondHalf: Tile[] = [];
      const firstHalf: Tile[] = [];
      tiles.forEach((tile, index) => {
        if (index % 2 === 0) {
          secondHalf.push(tile);
        } else {
          firstHalf.push(tile);
        }
      });

      setTileColumns([secondHalf, firstHalf]);
    }
  };

  useEffect(() => {
    if (data?.publicTiles && data?.publicTiles?.length > 0) {
      splitTilesInHalf(data?.publicTiles);
    } else {
      setTileColumns([]);
    }
  }, [data?.publicTiles]);

  // Modal operations
  const [present, dismiss] = useIonModal(TileModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
    tile: selectedTile,
  });

  function openModal(tile: Tile) {
    setSelectedTile(tile);
    present({
      onDidDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        setSelectedTile(undefined);
      },
    });
  }

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      refetch();
      event.detail.complete();
    }, 2000);
  }

  // handle favorite button click and change tile favorite status
  const handleFavoriteClick = (tile: Tile, index: number, colNum: number) => {
    if (tile?.isFavorite ?? false) {
      removeFavorite({ tileId: tile.id, currentUser: user })
        .then((success) => {
          if (success) {
            let copyOf = tileColumns[colNum][index]?.favorites ?? 1;
            copyOf = copyOf - 1;

            // replace the old tile with the new one
            setTileColumns((prev) => {
              const copy = [...prev];
              copy[colNum] = [...copy[colNum]];
              copy[colNum][index] = {
                ...copy[colNum][index],
                favorites: copyOf,
                isFavorite: false,
              };
              return copy;
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      addFavorite({ tileId: tile.id, currentUser: user })
        .then((success) => {
          if (success) {
            let copyOf = tileColumns[colNum][index]?.favorites ?? 0;
            copyOf = copyOf + 1;

            // replace the old tile with the new one
            setTileColumns((prev) => {
              const copy = [...prev];
              copy[colNum] = [...copy[colNum]];
              copy[colNum][index] = {
                ...copy[colNum][index],
                favorites: copyOf,
                isFavorite: true,
              };
              return copy;
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  // handle load more button click and load more tiles
  const handleLoadMoreClick = () => {
    nextPage();
  };

  return (
    <IonContent>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonGrid fixed={true}>
        <div className="tiles">
          {/* if publicTiles is empty display a sorry message */}
          {data?.publicTiles.length === 0 && (
            <IonText color={"primary"}>
              <div>Oops! Looks like there is nothing to show yet!</div>
            </IonText>
          )}
          <IonRow>
            {tileColumns.map((column, colIndex) => (
              <IonCol key={colIndex} size="12" size-md="6">
                {column.map((tile, index) => {
                  if (tile.image.length > 0)
                    return (
                      <div key={index + "tile"}>
                        <IonItem lines="none" key={index} className="tile">
                          <IonImg
                            onClick={() => openModal(tile)}
                            src={
                              tile?.image[0]?.photo ??
                              tile?.image[0] ??
                              "https://dummyimage.com/600x400/b0b0b0/000000&text=No+Image+Available"
                            }
                            className="tile-image"
                          ></IonImg>
                        </IonItem>
                        <div className="favorite-container">
                          <IonText color={"medium"}>
                            <span className="tile-author">{tile?.artist}</span>
                          </IonText>
                          <IonText color={"black"}>
                            <span className="tile-title">
                              <b>{tile.tileName}</b>
                            </span>
                          </IonText>
                          <div
                            onClick={() =>
                              handleFavoriteClick(tile, index, colIndex)
                            }
                          >
                            {
                              //  display favorite count if its greater than 0
                              !!tile?.favorites && (
                                <IonBadge style={{ fontSize: "1vh" }}>
                                  {tile?.favorites}
                                </IonBadge>
                              )
                            }
                            <IonIcon
                              color={tile.isFavorite ? "danger" : "medium"}
                              icon={tile.isFavorite ? heart : heartOutline}
                              style={{ fontSize: "3.5vh" }}
                            ></IonIcon>
                          </div>
                        </div>
                      </div>
                    );
                })}
              </IonCol>
            ))}
          </IonRow>
          {/* button for loading more */}
          {data?.publicTiles &&
            data?.publicTiles.length > 0 &&
            data?.publicTiles.length >=
              (20 * data?.pagination.page || 20) - 1 && (
              <IonRow>
                <IonCol>
                  <IonButton
                    expand="block"
                    onClick={handleLoadMoreClick}
                    disabled={
                      data?.publicTiles.length <
                      (20 * data?.pagination.page || 20)
                    }
                    style={{ marginTop: "auto" }}
                  >
                    Load More
                  </IonButton>
                </IonCol>
              </IonRow>
            )}
        </div>
      </IonGrid>
    </IonContent>
  );
};

export default PublicTileTable;
