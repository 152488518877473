import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { Tile } from "../../types/tiles";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  getDoc,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../firebase";
import { User } from "firebase/auth";

export const baseApi = createApi({
  baseQuery: fakeBaseQuery(),
  tagTypes: [
    "Tiles",
    "Tile",
    "UserTiles",
    "UserFits",
    "FavoriteTiles",
    "Favorites",
    "Page",
    "Categories",
  ],
  endpoints: (builder) => ({
    queryPublicTile: builder.query<Tile, { tileId: string }>({
      async queryFn({ tileId }) {
        try {
          const publicTilesRef = collection(db, "public");

          const publicTileQuery = query(
            publicTilesRef,
            where("id", "==", tileId)
          );
          const publicTileQuerySnapshot = await getDocs(publicTileQuery);

          if (!publicTileQuerySnapshot.empty) {
            const publicTile: Tile =
              publicTileQuerySnapshot?.docs[0]?.data() as Tile;

            return { data: publicTile };
          } else {
            return { error: "Tile not found" };
          }
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      providesTags: ["Tile"],
    }),
    queryFavorites: builder.query<string[], { currentUser?: User }>({
      async queryFn(currentUser: any) {
        try {
          if (currentUser && currentUser?.uid) {
            const userDocRef = doc(db, "users", currentUser.uid);

            const userDoc = await getDoc(userDocRef);

            const favoriteTiles = userDoc.data()?.favorites;
            return { data: favoriteTiles };
          } else {
            return { error: "User not found" };
          }
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      providesTags: ["Favorites"],
    }),
    queryPublicTiles: builder.query<
      {
        publicTiles: Tile[];
        pagination: { page: number; previousCursor: any };
      },
      { resLimit?: number; currentUser?: User | null }
    >({
      async queryFn({ resLimit, currentUser }, { dispatch, getState }) {
        try {
          const publicTilesRef = collection(db, "public");
          const publicTilesQuery = query(
            publicTilesRef,
            where("type", "==", "fits"),
            orderBy("createdAt", "desc"),
            limit(resLimit ?? 20)
          );

          const publicTilesSnapshot = await getDocs(publicTilesQuery);

          let previousCursor: any = "";

          let favoriteTiles: string[] = [];

          //   Get favorite tiles
          if (currentUser && currentUser?.uid) {
            const userDocRef = doc(db, "users", currentUser.uid);

            const userDoc = await getDoc(userDocRef);

            favoriteTiles = userDoc.data()?.favorites;
          }

          const publicTilesData = publicTilesSnapshot.docs.map(
            (doc, i, { length }) => {
              const data = doc.data();
              const id = doc.id;
              let isFavorite = false;

              if (favoriteTiles?.includes(id)) {
                isFavorite = true;
              }

              if (i === length - 1) {
                previousCursor = doc;
              }

              return { id, ...data, isFavorite } as Tile;
            }
          );

          if (publicTilesData?.length > 0) {
            return {
              data: {
                publicTiles: [...publicTilesData],
                pagination: { page: 0, previousCursor },
              },
            };
          } else {
            return {
              data: {
                publicTiles: [...publicTilesData],
                pagination: { page: 0, previousCursor },
              },
            };
          }
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      providesTags: ["Tiles"],
    }),
    // next page for public tiles
    queryNextPage: builder.query<
      {
        publicTiles: Tile[];
        pagination: { page: number; previousCursor: any };
      },
      void
    >({
      async queryFn(filler, { getState }) {
        try {
          const publicTilesRef = collection(db, "public");

          //  get current public tiles and current favorite tiles
          const publicTiles: any = (getState() as any).publicTilesApi?.data;
          const favoriteTiles: any = (getState() as any).publicTilesApi?.data;

          const newPage = publicTiles.pagination.page + 1;
          const prevCursor = publicTiles.pagination.previousCursor;

          const publicTilesQuery = query(
            publicTilesRef,
            orderBy("createdAt", "desc"),
            startAfter(prevCursor),
            limit(20)
          );
          const publicTilesSnapshot = await getDocs(publicTilesQuery);

          let previousCursor: any = "";
          const publicTilesData = publicTilesSnapshot?.docs?.map(
            (doc, i, { length }) => {
              const data = doc.data();
              const id = doc.id;
              let isFavorite = false;

              if (favoriteTiles.includes(id)) {
                isFavorite = true;
              }

              if (i === length - 1) {
                previousCursor = doc;
              }

              return { id, ...data, isFavorite };
            }
          );

          if (publicTilesData?.length > 0) {
            return {
              data: {
                publicTiles: [
                  ...publicTiles?.publicTiles,
                  ...(publicTilesData as Tile[]),
                ],
                pagination: { page: newPage, previousCursor },
              },
            };
          } else {
            return {
              data: {
                publicTiles: [
                  ...publicTiles?.publicTiles,
                  ...(publicTilesData as Tile[]),
                ],
                pagination: publicTiles.pagination,
              },
            };
          }
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      providesTags: ["Tiles"],
    }),
  }),
});
export const {
  useQueryPublicTileQuery,
  useQueryPublicTilesQuery,
  useQueryFavoritesQuery,
  useQueryNextPageQuery,
} = baseApi;
