import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonText,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import { heartSharp, homeSharp, bodySharp } from "ionicons/icons";
import "./Menu.scss";

import SignInScreen, { auth } from "../firebase";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "firebase/auth";
import Shirt from "../theme/icons/shirt.svg";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  isEnabled: boolean;
  children?: AppPage[];
}

interface MenuProps {
  loading: boolean;
  userChangeCB: (avatarImage: string) => void;
}

const appPagesDefault: AppPage[] = [
  {
    title: "My Wardrobe",
    url: "/wardrobe",
    iosIcon: bodySharp,
    mdIcon: bodySharp,
    isEnabled: true,
  },
  {
    title: "My Fits",
    url: "/fits",
    iosIcon: Shirt,
    mdIcon: Shirt,
    isEnabled: true,
  },
  {
    title: "Favorites",
    url: "/favorites",
    iosIcon: heartSharp,
    mdIcon: heartSharp,
    isEnabled: true,
  },
  {
    title: "Feed",
    url: "/home",
    iosIcon: homeSharp,
    mdIcon: homeSharp,
    isEnabled: true,
  },
];

const Menu: React.FC<MenuProps> = ({ loading, userChangeCB }) => {
  const [user, userLoading, error] = useAuthState(auth, {
    onUserChanged: (user) => userChange(user),
  });

  const location = useLocation();

  // App pages state
  const [appPages, setAppPages] = useState<AppPage[]>(appPagesDefault);

  const userChange = async (user: User | null) => {
    if (user) {
      const newAppPages = appPages.map((appPage) => {
        return appPage;
      });
      setAppPages(newAppPages);
    } else {
      setAppPages(appPagesDefault);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <div id="firebaseui-auth-container">
            <SignInScreen userChangeCB={userChangeCB}></SignInScreen>
          </div>

          {appPages.map((appPage, index) => {
            const isAccordian =
              appPage?.children && appPage?.children?.length > 0;
            if (isAccordian) {
              return (
                <IonAccordionGroup key={index}>
                  <IonAccordion value="first">
                    <IonItem
                      slot="header"
                      className={
                        location.pathname.includes(appPage.url)
                          ? "selected"
                          : ""
                      }
                      routerDirection="none"
                      lines="none"
                      disabled={!appPage.isEnabled}
                    >
                      <IonIcon
                        slot="start"
                        ios={appPage.iosIcon}
                        md={appPage.mdIcon}
                      />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                    <div className="menu-child" slot="content">
                      {appPage?.children?.map((child, index) => {
                        return (
                          <IonItem
                            className={
                              location.pathname === child.url ? "selected" : ""
                            }
                            key={index}
                            routerLink={child?.url}
                            routerDirection="none"
                            lines="none"
                            disabled={!child.isEnabled}
                          >
                            <IonIcon
                              slot="start"
                              ios={child.iosIcon}
                              md={child.mdIcon}
                            />
                            <IonLabel>{child.title}</IonLabel>
                          </IonItem>
                        );
                      })}
                    </div>
                  </IonAccordion>
                  <IonMenuToggle key={index} autoHide={false}></IonMenuToggle>
                </IonAccordionGroup>
              );
            } else {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={appPage?.url}
                    routerDirection="none"
                    lines="none"
                    detail={!!appPage.children}
                    disabled={!appPage.isEnabled}
                  >
                    <IonIcon
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            }
          })}
          {/* Market - Coming soon! */}
          {/* <IonItem
            className={location.pathname === "/market" ? "selected" : ""}
            routerLink="/market"
            routerDirection="none"
            lines="none"
            detail={false}
            disabled
          >
            <IonIcon
              slot="start"
              ios={storefrontOutline}
              md={storefrontSharp}
            />
            <IonLabel>Market - Coming soon!</IonLabel>
          </IonItem> */}
        </IonList>
      </IonContent>
      <IonFooter>
        {/* <IonItem
          className={location.pathname === "/about" ? "selected" : ""}
          routerLink="/about"
          routerDirection="none"
          lines="none"
          detail={false}
          disabled
        >
          <IonIcon slot="start" ios={happyOutline} md={happySharp} />
          <IonLabel>About - Coming soon!</IonLabel>
        </IonItem>

        <IonItem
          className={location.pathname === "/contact" ? "selected" : ""}
          routerLink="/contact"
          routerDirection="none"
          lines="none"
          detail={false}
          disabled
        >
          <IonIcon slot="start" ios={watchOutline} md={watchSharp} />
          <IonLabel>Contact - Coming soon!</IonLabel>
        </IonItem> */}
        {/* 
            <IonItem
              className={
                location.pathname === "/privacy" ? "selected" : ""
              }
              routerLink="/privacy"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" ios={lockClosedOutline} md={lockClosedSharp} />
              <IonLabel>Privacy</IonLabel>
            </IonItem>

            <IonItem
              className={
                location.pathname === "/terms" ? "selected" : ""
              }
              routerLink="/terms"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" ios={documentTextOutline} md={documentTextSharp} />
              <IonLabel>Terms</IonLabel>
            </IonItem> */}
        {/* copyright @mfreder7 */}

        <IonText className="ion-text-center">
          <p>
            <small>Copyright &copy; {new Date().getFullYear()} </small>
          </p>
        </IonText>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
