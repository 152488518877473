import {
  IonBadge,
  IonCol,
  IonContent,
  IonFab,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonText,
  isPlatform,
  RefresherEventDetail,
  useIonModal,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { heart, heartOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase";
import { baseApi } from "../../../store/api/baseApi";
import {
  useQueryFavoriteTilesQuery,
  useRemoveFavoriteMutation,
} from "../../../store/api/tilesApi";
import { useDispatch } from "react-redux";
import { Tile } from "../../../types/tiles";
import TileModal from "../../Modals/TileModal/TileModal";
import "./FavoritesTable.scss";

/**
 * A table of favorite tiles.
 *
 * @param param0 {name: string, sub: string, tiles: Tile[]}
 *
 */
const FavoritesTable: React.FC = () => {
  const isMobile =
    (isPlatform("mobile") || isPlatform("mobileweb")) && !isPlatform("ipad");
  const [selectedTile, setSelectedTile] = useState<Tile>();
  const [tileColumns, setTileColumns] = useState<Tile[][]>([]);
  const [user] = useAuthState(auth);
  const dispatch = useDispatch();

  const { data: favoriteTiles = [], refetch: queryFavoriteTiles } =
    useQueryFavoriteTilesQuery({ currentUser: user });
  const [removeFavorite] = useRemoveFavoriteMutation();

  const handleFavoriteClick = (tile: Tile, index: number, colNum: number) => {
    if (tile?.isFavorite) {
      removeFavorite({
        tileId: tile.id,
        currentUser: user,
      }).then(() => {
        setTimeout(() => {
          dispatch(baseApi.util.invalidateTags(["Tiles"]));
        }, 2000);
      });
    }
  };

  // split categories in half
  const splitTilesInHalf = (tiles: Tile[]) => {
    if (isMobile) {
      setTileColumns([tiles]);
    } else {
      const secondHalf: Tile[] = [];
      const firstHalf: Tile[] = [];
      tiles.forEach((tile, index) => {
        if (index % 2 === 0) {
          secondHalf.push(tile);
        } else {
          firstHalf.push(tile);
        }
      });

      setTileColumns([secondHalf, firstHalf]);
    }
  };

  useEffect(() => {
    if (favoriteTiles.length) {
      splitTilesInHalf(favoriteTiles);
    }
  }, [favoriteTiles]);

  // If user logs out, clear the tiles
  useEffect(() => {
    if (!user) {
      setTileColumns([]);
    }
  }, [user]);

  // Modal operations
  const [present, dismiss] = useIonModal(TileModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
    tile: selectedTile,
  });

  function openModal(tile: Tile) {
    setSelectedTile(tile);
    present({
      onDidDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        setSelectedTile(undefined);
      },
    });
  }

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      queryFavoriteTiles();
      event.detail.complete();
    }, 2000);
  }

  // split tiles into two arrays, one for the first column and one for the second

  return (
    <IonContent>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonGrid fixed={true}>
        <div className="tiles">
          {/* if no  favoriteTiles show error*/}
          {tileColumns.length === 0 && (
            <div className="no-tiles">
              <h1>Nothing found</h1>
              <p>
                You can favorite fits by clicking the heart icon on the lower
                right corner of the image.
              </p>
            </div>
          )}

          <IonRow>
            {tileColumns.map((column, colIndex) => (
              <IonCol key={colIndex} size="12" size-md="6">
                {column.map((tile, index) => {
                  return (
                    <div key={index + "tile"}>
                      <IonItem lines="none" key={index} className="tile">
                        <IonImg
                          onClick={() => openModal(tile)}
                          src={
                            tile?.image[0]?.photo ??
                            tile?.image[0] ??
                            "https://dummyimage.com/600x400/b0b0b0/000000&text=No+Image+Available"
                          }
                          className="tile-image"
                        ></IonImg>
                      </IonItem>
                      <div className="favorite-container">
                        <IonText color={"medium"}>
                          <span className="tile-author">{tile?.artist}</span>
                        </IonText>
                        <IonText color={"black"}>
                          <span className="tile-title">
                            <b>{tile.tileName}</b>
                          </span>
                        </IonText>
                        <div
                          onClick={() =>
                            handleFavoriteClick(tile, index, colIndex)
                          }
                        >
                          {
                            //  display favorite count if its greater than 0
                            !!tile?.favorites && (
                              <IonBadge style={{ fontSize: "1vh" }}>
                                {tile?.favorites}
                              </IonBadge>
                            )
                          }
                          <IonIcon
                            color={tile.isFavorite ? "danger" : "medium"}
                            icon={tile.isFavorite ? heart : heartOutline}
                            style={{ fontSize: "3.5vh" }}
                          ></IonIcon>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </IonCol>
            ))}
          </IonRow>
        </div>
      </IonGrid>
    </IonContent>
  );
};

export default FavoritesTable;
