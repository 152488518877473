import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { User } from "firebase/auth";
import "./Favorites.scss";
import { defaultAvatar, AvatarElement, auth } from "../../firebase";
import FavoritesTable from "../../components/Tables/FavoritesTable/FavoritesTable";
import { useAuthState } from "react-firebase-hooks/auth";
import { AvatarContext } from "../../App";
import Logo from "../../theme/icons/full-logo.svg";

interface FavoriteProps {
  loading: boolean;
}

const Favorites: React.FC<FavoriteProps> = ({ loading }) => {
  const [Avatar, setAvatar] = useState(defaultAvatar);
  const [user, userLoading] = useAuthState(auth, {
    onUserChanged: (user) => userChanged(user),
  });

  // useContext(AvatarContext) to get the avatar
  const changedAvatar = useContext(AvatarContext);

  // listen to changes in the avatar context
  useEffect(() => {
    if (changedAvatar) {
      setAvatar(AvatarElement(changedAvatar));
    }
  }, [changedAvatar]);

  // Set photo url to default if no photo url is provided
  const userChanged = async (newUser: User | null) => {
    if (!!newUser?.photoURL) {
      setAvatar(AvatarElement(newUser?.photoURL));
    } else {
      setAvatar(defaultAvatar);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <div className="title-wrapper">
            <IonIcon icon={Logo} className="logo" />
          </div>

          <div slot="secondary">{Avatar}</div>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {userLoading ? <div>loading...</div> : <FavoritesTable />}
      </IonContent>
    </IonPage>
  );
};

export default Favorites;
