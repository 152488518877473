import { Category } from "../../types/tiles";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import { User } from "firebase/auth";
import { baseApi } from "./baseApi";

export const categoriesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    queryCategories: builder.query<Category[], { currentUser?: User | null }>({
      async queryFn({ currentUser }) {
        try {
          if (currentUser && currentUser?.uid) {
            const q = query(
              collection(db, "users", currentUser?.uid, "categories"),
              orderBy("name")
            );
            const querySnapshot = await getDocs(q);
            const categories: Category[] = [];
            querySnapshot.forEach((doc) => {
              categories.push({ id: doc.id, ...doc.data() } as Category);
            });

            return { data: categories };
          } else {
            return { error: "User not found" };
          }
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      providesTags: ["Categories"],
    }),
  }),
});
export const { useQueryCategoriesQuery } = categoriesApi;
