import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonAvatar,
  IonText,
  IonNote,
  useIonLoading,
  useIonModal,
} from "@ionic/react";
import {
  eye,
  eyeOff,
  close,
  personAddOutline,
  personAddSharp,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { usePhotoGallery } from "../../../hooks/usePhotoGallery";

import "./AuthModals.scss";
import { auth } from "../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateProfile,
  User,
} from "firebase/auth";

type LoginProps = {
  onDismiss: () => void;
};
type RegisterProps = {
  onDismiss: () => void;
};

export const LoginModal: React.FC<LoginProps> = ({ onDismiss }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [user] = useAuthState(auth, {
    onUserChanged: (user) => handleUserLoggedIn(user),
  });

  // Register Modal
  const [presentReg] = useIonModal(RegisterModal, {
    onDismiss: () => {
      dismiss();
    },
  });

  // Email regex for validation
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleUserLoggedIn = async (user: User | null) => {
    if (user) {
      onDismiss();
    }
  };

  // Ion Loading
  const [present, dismiss] = useIonLoading();

  const handleLogin = async () => {
    present("Logging in...", undefined, "circular");

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        setError("Incorrect email or password");
      })
      .finally(() => {
        dismiss();
      });
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    if (emailRegex.test(e.target.value)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  // handle forgot password
  const handleForgotPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Password reset email sent!");
      })
      .catch((error) => {
        if (error.code === "auth/missing-email") {
          setError("Please enter an email.");
        }
      });
  };

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonList>
        <IonItem>
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            type="email"
            value={email}
            autocomplete="on"
            name="email"
            onIonChange={handleEmailChange}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Password</IonLabel>
          <IonInput
            type={showPassword ? "text" : "password"}
            value={password}
            autocomplete="on"
            name="password"
            className="password-icon"
            onIonChange={(e) => setPassword(e.detail.value!)}
          ></IonInput>
          <IonIcon
            slot="end"
            className="password-icon"
            icon={showPassword ? eyeOff : eye}
            onClick={() => setShowPassword(!showPassword)}
          />
        </IonItem>
      </IonList>
      <IonText color="danger" className="primary-error">
        {error}
      </IonText>

      <IonButton
        expand="block"
        onClick={() => {
          handleLogin();
        }}
        disabled={!emailValid || !password}
      >
        Login
      </IonButton>
      <IonText>
        <p className="forgot-password" onClick={handleForgotPassword}>
          Forgot password?
        </p>
      </IonText>
      <IonText>
        <p className="forgot-password" onClick={() => presentReg()}>
          Need to register?
        </p>
      </IonText>
    </IonContent>
  );
};

export const RegisterModal: React.FC<RegisterProps> = ({ onDismiss }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");

  const [userName, setUserName] = useState<string>("");
  const [userAvatar, setUserAvatar] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [passError, setPassError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [userNameError, setUserNameError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { takePhoto, photos, uploadPhotos, uploadedPhotos } = usePhotoGallery();
  const [user, userLoading, userError] = useAuthState(auth);
  const [present, dismiss] = useIonLoading();

  // Password regex for validation (at least 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character)
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  // Email regex for validation
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Username can only be letters, numbers, underscores, and dashes
  const usernameRegex = /^[a-zA-Z0-9_-]+$/;

  const handlePasswordChange = (e: any) => {
    setPassword(e.detail.value);
    // Check if password is valid
    if (passwordRegex.test(e.detail.value) || e.detail.value === "") {
      setPassError("");
    } else {
      setPassError(
        "Must be 8 characters long + 1 uppercase  + 1 lowercase + 1 number + 1 special."
      );
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.detail.value);
    // Check if email is valid
    if (emailRegex.test(e.detail.value) || e.detail.value === "") {
      setEmailError("");
    } else {
      setEmailError("Invalid email");
    }
  };

  const handleUsernameChange = (e: any) => {
    setUserName(e.detail.value);
    // Check if username is valid
    if (usernameRegex.test(e.detail.value) || e.detail.value === "") {
      setUserNameError("");
    } else {
      setUserNameError("Invalid username");
    }
  };

  // Verify second password entry matches first
  const handlePasswordConfirmChange = (e: any) => {
    setPasswordConfirm(e.detail.value);
    if (e.detail.value !== password) {
      if (!passError) setPassError("Passwords do not match");
    } else {
      setPassError("");
    }
  };

  useEffect(() => {
    if (photos.length > 0) {
      setUserAvatar(photos[0].webviewPath ?? "");
    }
  }, [photos]);

  const registerHandler = async () => {
    present();
    createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        if (response.user) {
          uploadPhotos().then((photo) => {
            updateProfile(response.user, {
              displayName: userName,
              photoURL: photo ? photo[0].photo : "",
            }).then(() => {
              dismiss();
              sendEmailVerification(response.user);
              window.location.reload();
              onDismiss();
            });
          });
        }
      })
      .catch((error) => {
        dismiss();
        if (error.code === "auth/email-already-in-use") {
          setError("Email already in use...");
        } else if (error.code === "auth/invalid-email") {
          setError("Invalid email...");
        } else {
          setError("Something went wrong... Please try again");
        }
      });
  };

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Register</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonList>
        <IonItem>
          <IonList className="user-register">
            {userAvatar ? (
              <IonAvatar style={{ cursor: "pointer" }} onClick={takePhoto}>
                <img src={userAvatar} />
              </IonAvatar>
            ) : (
              <IonIcon
                className="default-avatar"
                onClick={takePhoto}
                slot="start"
                size="large"
                ios={personAddOutline}
                md={personAddSharp}
              />
            )}
            <IonInput
              required
              type="text"
              className="user-name"
              value={userName}
              placeholder="Username"
              onIonChange={handleUsernameChange}
            ></IonInput>

            <IonText color="danger">{userNameError}</IonText>
          </IonList>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            required
            type="email"
            value={email}
            autocomplete="on"
            name="email"
            onIonChange={handleEmailChange}
          ></IonInput>
          <IonNote slot="error">
            <IonText color="danger">{emailError}</IonText>
          </IonNote>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Password</IonLabel>
          <IonInput
            required
            type={showPassword ? "text" : "password"}
            value={password}
            autocomplete="on"
            name="password"
            onIonChange={handlePasswordChange}
          ></IonInput>
          <IonIcon
            slot="end"
            className="password-icon"
            icon={showPassword ? eyeOff : eye}
            onClick={() => setShowPassword(!showPassword)}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Confirm Password</IonLabel>
          <IonInput
            required
            type={showPassword ? "text" : "password"}
            value={passwordConfirm}
            autocomplete="on"
            name="password"
            onIonChange={handlePasswordConfirmChange}
          ></IonInput>

          <IonNote className="password-error" slot="error">
            <IonText color="danger">{passError}</IonText>
          </IonNote>
        </IonItem>
      </IonList>
      <IonText color="danger" className="primary-error">
        {error}
      </IonText>
      <IonButton
        expand="block"
        onClick={() => {
          registerHandler();
        }}
        disabled={
          !!passError ||
          !passwordConfirm ||
          !!emailError ||
          !!userNameError ||
          !userName ||
          !email ||
          !password
        }
      >
        Register
      </IonButton>
    </IonContent>
  );
};
