import { useState } from "react";

import {
  Camera,
  CameraPermissionType,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera";
import { auth, storage } from "../firebase";
import { useUploadFile } from "react-firebase-hooks/storage";
import { ref } from "@firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDownloadURL, getMetadata } from "firebase/storage";
import { TilePhoto, UserPhoto } from "../types/tiles";
import { Capacitor } from "@capacitor/core";
import Resizer from "react-image-file-resizer";

async function resizeImage(webview: string, maxWidth: number): Promise<string> {
  const file = await fetch(webview).then((res) => res.blob());
  // resize webview image with Resizer
  return new Promise((resolve, reject) => {
    // resize file
    Resizer.imageFileResizer(
      file,
      maxWidth,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri as string);
      },
      "uri"
    );
  });
}

export function usePhotoGallery(preExistingPhotos: TilePhoto[] = []) {
  const formattedPhotos = preExistingPhotos?.map((photo) => {
    return {
      webviewPath: photo?.photo ?? photo,
      anchors: photo?.anchors,
    } as UserPhoto;
  });

  const [photos, setPhotos] = useState<UserPhoto[]>(formattedPhotos);
  const [uploadedPhotos, setUploadedPhotos] = useState<TilePhoto[]>([]);
  const [softUploadedPhotos, setSoftUploadedPhotos] = useState<TilePhoto[]>([]);
  const [user] = useAuthState(auth);
  // firebase storage image upload
  const [uploadFile] = useUploadFile();

  const takePhoto = async () => {
    // check camera permissions
    const cameraPhoto = await Camera.checkPermissions();
    if (cameraPhoto.camera !== "granted" || cameraPhoto.photos !== "granted") {
      // request camera permissions if on web browser
      if (Capacitor.isNativePlatform()) {
        const cameraPermissions = await Camera.requestPermissions({
          permissions: ["camera", "photos"] as CameraPermissionType[],
        });

        if (
          cameraPermissions.camera !== "granted" ||
          cameraPermissions.photos !== "granted"
        ) {
          return;
        }
      }
    }

    // if android, use camera plugin
    const fileName = new Date().getTime();

    const image = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
    });

    // set webPath to imageResize results
    const webPath = await resizeImage(image.webPath!, 500);

    console.log("webPath", webPath);
    // save photo locally
    const photo = {
      webviewPath: webPath,
      filepath: fileName + ".jpeg",
    } as UserPhoto;

    setPhotos([photo, ...photos]);
    // } else {
    //   const photo = await Camera.pickImages({
    //     width: 500,
    //     quality: 100,
    //   });

    //   const newPhotos = photo.photos.map((photo, index) => {
    //     return {
    //       filepath: fileName + `-${index}` + ".jpeg",
    //       webviewPath: photo.webPath,
    //     } as UserPhoto;
    //   });

    //   const newPhotosArray = [...photos, ...newPhotos];

    //   setPhotos(newPhotosArray);
    //   return newPhotosArray;
    // }
  };

  const softUploadPhotos = async () => {
    const newPhotos = photos?.filter((photo) => {
      return !formattedPhotos?.find(
        (fPhoto) => fPhoto.webviewPath === photo.webviewPath
      );
    });

    // updated photos
    const updatedPhotos = photos.filter((photo) => {
      return formattedPhotos?.find(
        (fPhoto) =>
          fPhoto.webviewPath === photo.webviewPath &&
          photo.anchors !== fPhoto.anchors
      );
    });

    // remaining photos
    const remainingPhotos = photos.filter((photo) => {
      return formattedPhotos?.find(
        (fPhoto) =>
          fPhoto.webviewPath === photo.webviewPath &&
          photo.anchors === fPhoto.anchors
      );
    });

    const promises = newPhotos
      .map(async (photo) => {
        if (photo?.webviewPath && photo?.filepath) {
          const response = await fetch(photo.webviewPath);
          const blob = await response.blob();
          const file = new File([blob], photo.filepath, {
            type: "image/jpeg",
          });

          const uploadTask = await uploadFile(
            ref(storage, `${user?.uid}/${photo?.filepath}`),
            file
          );
          return uploadTask;
        }
      })
      .map((promise) =>
        promise.then(async (res) => {
          if (res?.ref)
            return {
              downloadUrl: await getDownloadURL(res.ref),
              storageRef: await getMetadata(res.ref),
            };
        })
      );
    const urls = await Promise.all(promises);

    const newSoftUploadedPhotos = updatedPhotos.map((photo, index) => {
      return { photo: photo?.webviewPath, anchors: photo.anchors } as TilePhoto;
    });

    const newRemainingPhotos = remainingPhotos.map((photo, index) => {
      return { photo: photo?.webviewPath, anchors: photo.anchors } as TilePhoto;
    });

    // remove undefined values and cast into Photo type
    const filteredUrls = urls
      ?.filter(
        (url) =>
          url && url?.downloadUrl !== undefined && url?.storageRef !== undefined
      )
      .map((image) => {
        return {
          photo: image?.downloadUrl,
          storageReference: image?.storageRef.fullPath,
        } as TilePhoto;
      });

    setSoftUploadedPhotos([
      ...newSoftUploadedPhotos,
      ...filteredUrls,
      ...newRemainingPhotos,
    ]);
  };

  const uploadPhotos = async () => {
    try {
      setUploadedPhotos([]);
      const promises = photos
        .map(async (photo) => {
          if (photo?.webviewPath && photo?.filepath) {
            const response = await fetch(photo.webviewPath);
            const blob = await response.blob();
            const file = new File([blob], photo.filepath, {
              type: "image/jpeg",
            });
            const uploadTask = await uploadFile(
              ref(storage, `${user?.uid}/${photo?.filepath}`),
              file
            );
            return uploadTask;
          }
        })
        .map((promise) =>
          promise.then(async (res) => {
            if (res?.ref)
              return {
                downloadUrl: await getDownloadURL(res.ref),
                storageRef: await getMetadata(res.ref),
              };
          })
        );
      const urls = await Promise.all(promises);

      // remove undefined values and cast into Photo type
      const filteredUrls = urls
        ?.filter(
          (url) =>
            url &&
            url?.downloadUrl !== undefined &&
            url?.storageRef !== undefined
        )
        .map((image) => {
          return {
            photo: image?.downloadUrl,
            storageReference: image?.storageRef,
          } as TilePhoto;
        });

      setUploadedPhotos(filteredUrls);
      setPhotos([]);
      return filteredUrls;
    } catch (error) {
      console.log("error", error);
      alert("Error uploading photos");
    }
  };

  return {
    photos,
    uploadedPhotos,
    softUploadedPhotos,
    takePhoto,
    uploadPhotos,
    softUploadPhotos,
    setUploadedPhotos,
    setPhotos,
  };
}
