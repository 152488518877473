import React from "react";
import "./QualityIndicator.scss";

//QualityIndicatorProps
export interface QualityIndicatorProps {
  quality?: string;
}

const qualityColor = (quality?: string) => {
  switch (quality) {
    case "new":
      return "#00E186";
    case "like new":
      return "#C3FF64";
    case "good":
      return "#FFEB78";
    case "fair":
      return "#FF8A48";
    case "poor":
      return "#FF6856";
    default:
      return;
  }
};

// Quality indicator element
export const QualityIndicator: React.FC<QualityIndicatorProps> = ({
  quality,
}) => {
  const color = qualityColor(quality);

  return (
    <>
      <span
        style={{
          color: color,
        }}
      >
        <sub>{quality?.toUpperCase()}</sub>
      </span>
      <span
        className="quality-indicator-bar"
        style={{
          backgroundColor: color,
        }}
      ></span>
    </>
  );
};
