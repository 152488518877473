import {
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonImg,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  RefresherEventDetail,
  useIonModal,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { add } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { Category, Tile } from "../../../types/tiles";
import { TilesContext } from "../../ExploreContainer";
import CreateModal from "../../Modals/CreateModal/CreateModal";
import TileModal from "../../Modals/TileModal/TileModal";
import "./ExploreTileTable.scss";

interface TileTableProps {
  appliedFilters?: string[];
  appliedFiltersNames?: string[];
  categories?: Category[];
  name?: string;
  newTileCallback: () => void;
}

/**
 * A table of tiles.
 *
 * @param param0 {name: string, sub: string, tiles: Tile[]}
 *
 */
const ExploreTileTable: React.FC<TileTableProps> = ({
  appliedFilters,
  appliedFiltersNames,
  categories,
  name,
  newTileCallback,
}) => {
  const [selectedTile, setSelectedTile] = useState<Tile | undefined>();
  const [editTile, setEditTile] = useState<Tile>();
  const [tileColumns, setTileColumns] = useState<Tile[][]>([]);
  // use tilesContext
  const tiles = useContext(TilesContext);

  const [present, dismiss] = useIonModal(TileModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
    newTileCallback: newTileCallback,
    name: name === "wardrobe" ? "tile" : name,
    tile: selectedTile,
    tiles: tiles,
    categories: categories,
  });

  function openModal(tile: Tile) {
    setSelectedTile(tile);
    present({
      onDidDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        setSelectedTile(undefined);
      },
    });
  }

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      newTileCallback();
      event.detail.complete();
    }, 2000);
  }

  const [presentCreateTile, dismissTileCreator] = useIonModal(CreateModal, {
    onDismiss: (data: string, role: string) => dismissTileCreator(data, role),
    name: name === "wardrobe" ? "tile" : "fits",
    tileState: editTile,
    tiles,
    categories,
  });

  const openCreateModal = (tile?: Tile) => {
    setEditTile(tile);
    presentCreateTile({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        newTileCallback(); // update the list of tiles
      },
    });
  };

  // split categories in half
  const splitTilesInHalf = (tiles: Tile[]) => {
    const secondHalf: Tile[] = [];
    const firstHalf: Tile[] = [];
    tiles.forEach((tile, index) => {
      if (index % 2 === 0) {
        secondHalf.push(tile);
      } else {
        firstHalf.push(tile);
      }
    });

    setTileColumns([secondHalf, firstHalf]);
  };

  useEffect(() => {
    if (tiles?.length) {
      let filteredTiles = tiles;

      if (appliedFiltersNames?.length) {
        // remove tiles not in applied filters list
        filteredTiles = tiles.filter((tile) =>
          appliedFilters?.includes(tile.id)
        );
      }

      splitTilesInHalf(filteredTiles);
    } else {
      setTileColumns([]);
    }
  }, [tiles, appliedFilters]);

  // split tiles into two arrays, one for the first column and one for the second

  return (
    <IonContent>
      <IonFab vertical="bottom" horizontal="center" slot="fixed">
        <IonFabButton onClick={() => openCreateModal()}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonGrid fixed={true}>
        <div className="tiles">
          {/* if no tiles display error */}
          {!tiles?.length && (
            <div className="no-tiles">
              <h1>Nothing found</h1>
              <p>
                You can create a new entry by clicking the button in the bottom
                center.
              </p>
            </div>
          )}
          <IonRow>
            <IonCol>
              {tileColumns[0]?.map((tile, index) => {
                return (
                  <div key={index} className="tile">
                    <IonImg
                      src={
                        tile?.image[0]?.photo ??
                        tile?.image[0] ??
                        "https://dummyimage.com/600x400/b0b0b0/000000&text=No+Image+Available"
                      }
                      onClick={() => openModal(tile)}
                    />
                  </div>
                );
              })}
            </IonCol>
            <IonCol>
              {tileColumns[1]?.map((tile, index) => {
                return (
                  <div key={index} className="tile">
                    <IonImg
                      src={
                        tile?.image[0]?.photo ??
                        tile?.image[0] ??
                        "https://dummyimage.com/600x400/b0b0b0/000000&text=No+Image+Available"
                      }
                      onClick={() => openModal(tile)}
                    />
                  </div>
                );
              })}
            </IonCol>
          </IonRow>
        </div>
      </IonGrid>
    </IonContent>
  );
};

export default ExploreTileTable;
