export const sizes = [
  "xs - mens",
  "xs - womens",
  "s - mens",
  "s - womens",
  "m - mens",
  "m - womens",
  "l - mens",
  "l - womens",
  "xl - mens",
  "xl - womens",
  "xxl - mens",
  "xxl - womens",
  "xxxl - mens",
  "xxxl - womens",
];
export const conditions = ["new", "like new", "good", "fair", "poor"];
export const colors = [
  "black",
  "white",
  "red",
  "orange",
  "yellow",
  "green",
  "blue",
  "purple",
  "brown",
  "grey",
  "pink",
  "gold",
  "silver",
  "multicolor",
  "other",
];
export const patterns = [
  "plain",
  "striped",
  "polka dot",
  "plaid",
  "floral",
  "camo",
  "checkered",
  "other",
];
export const materials = [
  "cotton",
  "polyester",
  "silk",
  "wool",
  "leather",
  "denim",
  "other",
];
