import "./ExploreContainer.scss";
import { createContext, useEffect, useState } from "react";
import {
  IonBreadcrumbs,
  IonChip,
  IonItemDivider,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import ExploreTileTable from "./Tables/ExploreTileTable/ExploreTileTable";
import { Category, Tile } from "../types/tiles";
import { useHistory } from "react-router-dom";
import { useQueryCategoriesQuery } from "../store/api/categoriesApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import {
  useQueryUserFitsQuery,
  useQueryUserTilesQuery,
} from "../store/api/tilesApi";

// create context for categories and tiles
export const TilesContext = createContext<Tile[]>([]);

interface ContainerProps {
  refreshMenu: () => void;
}

const ExploreContainer: React.FC<ContainerProps> = ({ refreshMenu }) => {
  const [user] = useAuthState(auth);
  const [dispCategories, setDispCategories] = useState<Category[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
  const [filteredTileIds, setFilteredTileIds] = useState<string[]>([]);
  // url name state
  const [urlName, setUrlName] = useState<string>("");

  const {
    data = [],
    isLoading,
    refetch,
  } = useQueryCategoriesQuery({ currentUser: user });

  // use tilesApi
  const {
    data: userTiles,
    refetch: queryUserTiles,
    isLoading: queryUserTilesLoading,
  } = useQueryUserTilesQuery({
    currentUser: user,
  });
  const {
    data: userFits,
    refetch: queryUserFits,
    isLoading: queryUserFitsLoading,
  } = useQueryUserFitsQuery({ currentUser: user });

  const history = useHistory();

  // setTiles callback from closing modal
  const newTileCallback = () => {
    if (urlName !== "fits") {
      queryUserTiles();
      refetch();
    } else queryUserFits();
  };

  const nameChangeHandler = async (path: string) => {
    if (path === "wardrobe" && !queryUserTilesLoading) {
      if (!isLoading) refetch();
      queryUserTiles();
    } else if (path === "fits" && !queryUserFitsLoading) {
      if (!isLoading) refetch();
      queryUserFits();
    }
  };

  // cleanup when name changes
  useIonViewWillEnter(() => {
    // get url name
    const path = history.location.pathname.split("/");

    // set url name state to last path
    setUrlName(path[path.length - 1]);

    nameChangeHandler(path[path.length - 1]);
  }, []);

  // set display categories when categories change
  useEffect(() => {
    if (data?.length) {
      setDispCategories(data);
    }
  }, [data]);

  useEffect(() => {
    // get url name
    const path = history.location.pathname.split("/");

    // set url name state to last path
    setUrlName(path[path.length - 1]);

    nameChangeHandler(path[path.length - 1]);
  }, []);

  // handle filter chip toggle
  const handleFilterClick = (filter: Category) => {
    if (appliedFilters.includes(filter.name)) {
      setAppliedFilters(appliedFilters.filter((f) => f !== filter.name));
      // remove filter.tiles from filteredTileIds
      setFilteredTileIds(
        filteredTileIds.filter((id) => !filter.tiles.includes(id))
      );
    } else {
      setAppliedFilters([...appliedFilters, filter.name]);
      setFilteredTileIds([...filteredTileIds, ...filter.tiles]);
    }
  };

  if (urlName !== "fits")
    return (
      <div className="container">
        <IonItemDivider sticky={true} className="divider-sticky">
          {/* x scroll container */}
          <IonBreadcrumbs className="filter-container">
            {/* Chips for category filters */}
            {dispCategories.map((category, index) => {
              if (category.name !== "All")
                return (
                  <IonChip
                    key={index}
                    onClick={() => {
                      handleFilterClick(category);
                    }}
                    outline={!appliedFilters.includes(category.name)}
                    className="filter-chip"
                  >
                    <IonText>
                      {/* small text */}
                      <p style={{ fontSize: "70%" }}>{category.name}</p>
                    </IonText>
                  </IonChip>
                );
            })}
          </IonBreadcrumbs>
        </IonItemDivider>
        <TilesContext.Provider value={userTiles ?? []}>
          <ExploreTileTable
            categories={data}
            name={urlName}
            appliedFilters={filteredTileIds}
            appliedFiltersNames={appliedFilters}
            newTileCallback={newTileCallback}
          />
        </TilesContext.Provider>
      </div>
    );
  else {
    return (
      <div className="container">
        <TilesContext.Provider value={userFits ?? []}>
          <ExploreTileTable
            categories={data}
            name={urlName}
            newTileCallback={newTileCallback}
          />
        </TilesContext.Provider>
      </div>
    );
  }
};

export default ExploreContainer;
