import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonPopover,
} from "@ionic/react";
import { closeOutline, closeSharp } from "ionicons/icons";

import { useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ImageAnchor from "../../../elements/ImageAnchor/ImageAnchor";

import {
  Anchor,
  Coordinates,
  Tile,
  TilePhoto,
  UserPhoto,
} from "../../../types/tiles";

import "./AnchorModal.scss";

type Props = {
  onDismiss: (data?: TilePhoto | UserPhoto | any, role?: string) => void;
  photo?: TilePhoto;
  userPhoto?: UserPhoto;
  catTiles?: {
    id: string;
    tiles: Tile[];
  }[];
};

type PopoverProps = {
  onDismissFirst: (data?: Partial<Anchor>, role?: string) => void;
  catTiles: { id: string; tiles: Tile[] }[];
};

type TilePopoverProps = {
  onDismiss: (data?: Partial<Anchor>, role?: string) => void;
  tiles: Tile[];
};
const CategoriesPopOver: React.FC<PopoverProps> = ({
  onDismissFirst,
  catTiles,
}) => {
  const [catList, setCatList] =
    useState<{ id: string; tiles: Tile[] }[]>(catTiles);
  const [selectedCat, setSelectedCat] = useState<Tile[]>();

  return (
    <IonContent className="anchor-list">
      <IonList>
        <IonAccordionGroup>
          {catList?.map((item, index) => {
            if (item.id !== "All" && item?.tiles?.length > 0) {
              return (
                // Accordian for each category with nested items
                <IonAccordion key={index} value={item.id}>
                  <IonItem slot="header">
                    <IonLabel>
                      <IonTitle>{item.id}</IonTitle>
                    </IonLabel>
                  </IonItem>

                  <div slot="content">
                    {item.tiles?.map((tile, itemIndex) => {
                      return (
                        <IonItem
                          key={itemIndex}
                          onClick={() => {
                            onDismissFirst(
                              {
                                id: tile.id,
                                name: tile.tileName,
                                isPublic: tile.isPublic,
                              },
                              "save"
                            );
                          }}
                        >
                          <IonThumbnail slot="start">
                            <IonImg src={tile.image[0].photo} />
                          </IonThumbnail>
                          <IonLabel>{tile.tileName}</IonLabel>
                        </IonItem>
                      );
                    })}
                  </div>
                </IonAccordion>
              );
            }
          })}
        </IonAccordionGroup>
      </IonList>
    </IonContent>
  );
};

// React.FC<ContainerProps> = ({ name, sub }) => {

const AnchorModal: React.FC<Props> = ({
  onDismiss,
  photo,
  userPhoto,
  catTiles,
}) => {
  const [coordinates, setCurrentCoords] = useState<Coordinates>();
  // anchors hook
  const [anchors, setAnchors] = useState<Anchor[]>(
    photo?.anchors ?? userPhoto?.anchors ?? []
  );

  // tile list popover
  const [present, dismiss] = useIonPopover(CategoriesPopOver, {
    onDismissFirst: (data: any, role: string) => {
      dismiss(data, role);
      setAnchors((prev) => [...prev, { ...data, coordinates }]);
    },
    catTiles: catTiles,
  });

  const deleteAnchor = (anchor: Anchor) => {
    setAnchors((prev) => {
      return prev.filter((a) => a.id !== anchor.id);
    });
  };

  // function to get click coordinates within the image as a percentage
  function assignAnchor(e: any) {
    const x = e?.nativeEvent?.offsetX;
    const y = e?.nativeEvent?.offsetY;
    const width = e?.target?.offsetWidth;
    const height = e?.target?.offsetHeight;

    // percentage insets of where the user clicked in the image
    const xInset = (x / width) * 100;
    const yInset = (y / height) * 100;
    setCurrentCoords({ x: xInset, y: yInset });

    present({
      event: e,
      reference: "event",
    });
  }

  function save() {
    if (photo) {
      onDismiss({ ...photo, anchors }, "save");
    } else if (userPhoto) {
      onDismiss({ ...userPhoto, anchors }, "save");
    } else {
      onDismiss(undefined, "cancel");
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color="medium"
              onClick={() => onDismiss({ ...(photo ?? userPhoto) }, "cancel")}
            >
              <IonIcon slot="start" ios={closeOutline} md={closeSharp} />
            </IonButton>
          </IonButtons>
          <IonTitle>Tag wardrobe items</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={() => save()}>Save</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="modal-container">
          <Swiper
            className="creator-swiper above-everything"
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            <SwiperSlide>
              <IonImg
                className="modal-image"
                class="uploaded-image"
                src={photo?.photo ?? userPhoto?.webviewPath}
                onClick={(e) => assignAnchor(e)}
              />
              {anchors?.map((anchor, index) => {
                return (
                  <ImageAnchor
                    key={index}
                    anchor={anchor}
                    deleteAnchor={deleteAnchor}
                  />
                );
              })}
            </SwiperSlide>
          </Swiper>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AnchorModal;
