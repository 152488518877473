import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCardContent,
  IonCardSubtitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonModal,
} from "@ionic/react";
import {
  closeOutline,
  closeSharp,
  createOutline,
  createSharp,
} from "ionicons/icons";

import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Category, Tile } from "../../../types/tiles";

import "./TileModal.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import CreateModal from "../CreateModal/CreateModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import ImageAnchor from "../../../elements/ImageAnchor/ImageAnchor";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebase";
import { useQueryCategoriesQuery } from "../../../store/api/categoriesApi";

type Props = {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  tile: Tile;
  tiles: Tile[];
  categories?: Category[];
  newTileCallback?: () => void;
  name: string;
};

// React.FC<ContainerProps> = ({ name, sub }) => {

const TileModal: React.FC<Props> = ({
  onDismiss,
  tile,
  newTileCallback,
  tiles,
}) => {
  const [user] = useAuthState(auth);
  const [editTile, setEditTile] = useState<Tile>();

  const { data = [] } = useQueryCategoriesQuery({ currentUser: user });

  const [presentCreateTile, dismissTileCreator] = useIonModal(CreateModal, {
    onDismiss: (data: string, role: string) => dismissTileCreator(data, role),
    name: tile?.type === "tile" ? "tile" : "fits",
    tileState: editTile,
    tiles: tiles,
    categories: data,
  });

  const openCreateModal = (tile?: Tile) => {
    setEditTile(tile);
    presentCreateTile({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (newTileCallback) newTileCallback(); // update the list of tiles
        onDismiss(null, "cancel");
      },
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="medium" onClick={() => onDismiss(null, "cancel")}>
              <IonIcon slot="start" ios={closeOutline} md={closeSharp} />
            </IonButton>
          </IonButtons>
          <IonTitle>{tile?.tileName}</IonTitle>
          {tile?.artistId === user?.uid && (
            <IonButtons slot="end">
              <IonButton
                onClick={() => openCreateModal(tile)}
                className="edit-button"
                size="small"
              >
                <IonIcon ios={createOutline} md={createSharp} />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          className="creator-swiper above-everything"
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {tile?.image?.map((photo, index) => {
            if (photo?.anchors) {
              return (
                <SwiperSlide key={index}>
                  <IonImg
                    className="modal-image"
                    class="uploaded-image"
                    src={
                      photo?.photo ??
                      "https://dummyimage.com/600x400/b0b0b0/000000&text=No+Image+Available"
                    }
                  ></IonImg>
                  {photo?.anchors?.map((anchor, index) => {
                    return (
                      <ImageAnchor
                        key={index}
                        anchor={anchor}
                        categories={data}
                      />
                    );
                  })}
                </SwiperSlide>
              );
            }

            return (
              <SwiperSlide key={index}>
                <IonImg
                  className="modal-image"
                  class="uploaded-image"
                  src={
                    photo?.photo ??
                    photo ??
                    "https://dummyimage.com/600x400/b0b0b0/000000&text=No+Image+Available"
                  }
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <IonItem>
          <IonLabel>
            <IonCardSubtitle>Designed by</IonCardSubtitle>
            <IonCardContent class="description-text">
              {tile?.artist}
            </IonCardContent>
            <IonCardSubtitle>Description</IonCardSubtitle>
            {/* wrapping text */}
            <IonCardContent class="description-text">
              {tile?.description}
            </IonCardContent>
            {tile?.type === "tile" && (
              <div>
                <IonCardSubtitle>Category</IonCardSubtitle>
                <IonCardContent class="description-text">
                  {tile?.category}
                </IonCardContent>
              </div>
            )}

            {/* TODO: Re-implement for marketplace */}
            {/* <div>Price: {tile?.price}</div>
            <div>Quantity: {tile?.quantity}</div>
            <div>
              {tile?.isAvailable ? "Available for purchase" : "Out of stock"}
            </div> */}
          </IonLabel>
        </IonItem>
        {tile?.type === "tile" && (
          <IonAccordionGroup>
            <IonAccordion value="first">
              <IonItem slot="header">
                <IonCardSubtitle color={"medium"}>
                  Advanced details
                </IonCardSubtitle>
              </IonItem>
              <div slot="content">
                <IonItem className="advanced-display">
                  <IonLabel>
                    <IonCardSubtitle>Condition:</IonCardSubtitle>
                    <IonCardContent class="description-text">
                      {tile?.condition}
                    </IonCardContent>

                    <IonCardSubtitle>Size:</IonCardSubtitle>
                    <IonCardContent class="description-text">
                      {tile?.size}
                    </IonCardContent>

                    <IonCardSubtitle>Brand:</IonCardSubtitle>
                    <IonCardContent class="description-text">
                      {tile?.brand}
                    </IonCardContent>

                    <IonCardSubtitle>Color:</IonCardSubtitle>
                    <IonCardContent class="description-text">
                      {tile?.color}
                    </IonCardContent>

                    <IonCardSubtitle>Pattern:</IonCardSubtitle>
                    <IonCardContent class="description-text">
                      {tile?.pattern}
                    </IonCardContent>

                    <IonCardSubtitle>Material:</IonCardSubtitle>
                    <IonCardContent class="description-text">
                      {tile?.material}
                    </IonCardContent>

                    <IonCardSubtitle>Measurement:</IonCardSubtitle>
                    <IonCardContent class="description-text">
                      {tile?.measurements}
                    </IonCardContent>
                  </IonLabel>
                </IonItem>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        )}
        <IonCardSubtitle>Purchase: Coming soon!</IonCardSubtitle>
      </IonContent>
    </IonPage>
  );
};

export default TileModal;
