import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.scss";
import { defaultAvatar, AvatarElement, auth } from "../../firebase";
import PublicTileTable from "../../components/Tables/PublicTable/PublicTileTable";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "firebase/auth";
import { AvatarContext } from "../../App";
import Logo from "../../theme/icons/full-logo.svg";
import LogoDark from "../../theme/icons/full-logo-dark.svg";

interface DashboardProps {
  loading: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ loading }) => {
  const [Avatar, setAvatar] = useState(defaultAvatar);
  const [user] = useAuthState(auth, {
    onUserChanged: (user) => userChanged(user),
  });

  // useContext(AvatarContext) to get the avatar
  const changedAvatar = useContext(AvatarContext);

  // listen to changes in the avatar context
  useEffect(() => {
    if (changedAvatar) {
      setAvatar(AvatarElement(changedAvatar));
    }
  }, [changedAvatar]);

  // Set photo url to default if no photo url is provided
  const userChanged = async (newUser: User | null) => {
    if (!!newUser?.photoURL) {
      setAvatar(AvatarElement(newUser?.photoURL));
    } else {
      setAvatar(defaultAvatar);
    }
  };

  // // Add a state variable to store the value of the boolean for dark mode
  // const [isDarkMode, setIsDarkMode] = useState(false);

  // // Check for the user's preferred color scheme and set the state variable accordingly
  // useEffect(() => {
  //   const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
  //   setIsDarkMode(darkModeQuery.matches);

  //   const handleChange = (e: MediaQueryListEvent) => {
  //     setIsDarkMode(e.matches);
  //   };

  //   darkModeQuery.addEventListener("change", handleChange);

  //   return () => {
  //     darkModeQuery.removeEventListener("change", handleChange);
  //   };
  // }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <div className="title-wrapper">
            {/* {isDarkMode ? (
              <IonIcon icon={LogoDark} className="logo" size="large" />
            ) : ( */}
            <IonIcon icon={Logo} className="logo" size="large" />
            {/* )} */}
          </div>

          <div slot="secondary">{Avatar}</div>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {loading ? <div>loading...</div> : <PublicTileTable />}
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
