import { IonButton, IonIcon, useIonModal } from "@ionic/react";

import "./ImageAnchor.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  cashOutline,
  closeCircle,
  ellipse,
  eyeOutline,
  trash,
} from "ionicons/icons";
import { useState } from "react";
import { CreateAnimation } from "@ionic/react";
import React from "react";

import { Anchor } from "../../types/tiles";
import TileModal from "../../components/Modals/TileModal/TileModal";
import { QualityIndicator } from "../QualityIndacator/QualityIndicator";
import { useQueryPublicTileQuery } from "../../store/api/baseApi";

type Props = {
  anchor: Anchor;
  deleteAnchor?: (anchor: Anchor) => void;
  categories?: any;
};

const ImageAnchor: React.FC<Props> = ({ anchor, deleteAnchor, categories }) => {
  // display name of anchor
  const [showName, setShowName] = useState(false);

  const { data } = useQueryPublicTileQuery({ tileId: anchor.id });

  const [present, dismiss] = useIonModal(TileModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
    tile: data,
    categories: categories,
  });

  // display anchor name and icon when clicked on
  function handleClick() {
    setShowName(!showName);
  }

  function handleLinking() {
    present();
  }

  if (!data) return <></>;

  if (anchor.isPublic)
    return (
      <div className="anchor-container above-everything">
        <IonIcon
          className={showName ? "anchor full-opactiy" : "anchor"}
          style={{
            position: "absolute",
            color: "#e7e9eb",
            top: anchor?.coordinates?.y.toString() + "%",
            left: anchor?.coordinates?.x.toString() + "%",
          }}
          onClick={handleClick}
          icon={showName ? closeCircle : ellipse}
        ></IonIcon>

        <CreateAnimation
          play={showName}
          duration={250}
          iterations={1}
          keyframes={[
            {
              offset: 0,
              transform: "translateX(0)",
              opacity: "0",
            },

            {
              offset: 1,
              transform:
                anchor?.coordinates?.x > 68
                  ? `translateX(-135px)`
                  : "translateX(15px)",
              opacity: "1",
            },
          ]}
        >
          <div
            className="anchor-name"
            style={{
              position: "absolute",
              // set a max coordinate to prevent the anchor name from going off the screen
              top: anchor?.coordinates?.y.toString() + "%",

              left: (anchor?.coordinates?.x).toString() + "%",
              visibility: showName ? "visible" : "hidden",
              cursor: "pointer",
            }}
          >
            <div className="anchor-name-text">{anchor?.name}</div>

            <div className="anchor-name-body">
              <QualityIndicator
                quality={data?.condition ?? "No quality listed"}
              ></QualityIndicator>
              <span>Price: ${data?.price ?? "Not listed."}</span>
            </div>

            {/* green icon button with a money icon */}
            <div className="anchor-name-footer">
              <IonButton size="small" onClick={handleLinking}>
                <IonIcon slot="icon-only" icon={eyeOutline}></IonIcon>
              </IonButton>
              <IonButton disabled className="" size="small">
                <IonIcon slot="icon-only" icon={cashOutline}></IonIcon>
              </IonButton>
              {!!deleteAnchor && (
                <IonIcon
                  icon={trash}
                  onClick={() => deleteAnchor(anchor)}
                ></IonIcon>
              )}
            </div>
          </div>
        </CreateAnimation>
      </div>
    );
  else return <></>;
};

export default ImageAnchor;
