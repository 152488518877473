import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ExploreContainer from "../components/ExploreContainer";
import { User } from "firebase/auth";

import "./Page.scss";
import { defaultAvatar, AvatarElement, auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { AvatarContext } from "../App";

// import full-logo.svg
import Logo from "../theme/icons/full-logo.svg";

interface PageProps {
  refreshMenu: () => void;
  loading: boolean;
}

const Page: React.FC<PageProps> = ({ loading, refreshMenu }) => {
  const [user] = useAuthState(auth, {
    onUserChanged: (user) => userChanged(user),
  });

  const [Avatar, setAvatar] = useState(defaultAvatar);

  // useContext(AvatarContext) to get the avatar
  const changedAvatar = useContext(AvatarContext);

  // listen to changes in the avatar context
  useEffect(() => {
    if (changedAvatar) {
      setAvatar(AvatarElement(changedAvatar));
    }
  }, [changedAvatar]);

  // Set photo url to default if no photo url is provided
  const userChanged = async (newUser: User | null) => {
    if (!!newUser?.photoURL) {
      setAvatar(AvatarElement(newUser?.photoURL));
    } else {
      setAvatar(defaultAvatar);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="toolbar">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <div className="title-wrapper">
            <IonIcon icon={Logo} className="logo" />
          </div>

          <div slot="secondary">{Avatar}</div>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {loading ? (
          <div>loading...</div>
        ) : user?.uid ? (
          <ExploreContainer refreshMenu={refreshMenu} />
        ) : (
          <IonText color={"primary"}>
            <div>Woops.. You need to be logged in to see this!</div>
          </IonText>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Page;
