import { TilePhoto, UserPhoto } from "../types/tiles";

// UserPhoto type guard
export function isTilePhoto(photo: any): photo is TilePhoto {
  return (photo as TilePhoto)?.photo !== undefined;
}

export function isUserPhoto(photo: any): photo is UserPhoto {
  return (photo as UserPhoto)?.filepath !== undefined;
}
