import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonicSlides,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonModal,
} from "@ionic/react";
import { closeOutline, closeSharp, pricetagsOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { usePhotoGallery } from "../../../hooks/usePhotoGallery";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { auth, functions } from "../../../firebase";
import { Category, Tile, TilePhoto, UserPhoto } from "../../../types/tiles";
import AnchorModal from "../AnchorModal/AnchorModal";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { isTilePhoto } from "../../../guards/photoguards";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "./CreateModal.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ImageAnchor from "../../../elements/ImageAnchor/ImageAnchor";
import {
  colors,
  conditions,
  materials,
  patterns,
  sizes,
} from "../../../types/creator";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  useCreateFitMutation,
  useCreateTileMutation,
  useDeleteFitMutation,
  useDeleteTileMutation,
  useQueryUserTilesQuery,
  useUpdateFitFunctionsMutation,
  useUpdateTileMutation,
} from "../../../store/api/tilesApi";

type Props = {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  categoryState?: Category;
  name?: string;
  tileState?: Tile;
  tiles?: Tile[];
  categories?: Category[];
  tileData?: { categoryName?: string; collectionName?: string };
};

type TileBuilderProps = {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  tileState?: Tile;
  tiles?: Tile[];
  categories?: Category[];
  tileData?: { categoryName?: string; collectionName?: string };
  name?: string;
};

// @deprecated
// This is what handles rendering and creating the categories.
const CollectionBuilder: React.FC<Props> = ({
  categoryState,
  name,
  tiles,
  categories,
  onDismiss,
}) => {
  const [createTileCategory, loading, err] = useHttpsCallable(
    functions,
    "createTileCategory"
  );

  const [text, setText] = useState<string>();

  // This is the function that handles the creation of the tile collection after uploading to the cloud storage.
  useEffect(() => {}, []);

  // Handles create category button.
  const handleCreateCategory = async () => {
    if (text) {
      await createTileCategory({ name: text });
      onDismiss();
    }
  };

  // TODO: add if check on name to determine if it's a new category or fits.
  if (name === "fits")
    return (
      <div className="creator">
        <TileBuilder
          onDismiss={onDismiss}
          tiles={tiles}
          categories={categories}
          name={name}
        ></TileBuilder>
      </div>
    );
  else
    return (
      <div className="creator">
        <IonItem>
          <IonLabel position="stacked">Category name</IonLabel>
          <IonInput
            onIonInput={(e: any) => {
              setText(e.target.value);
            }}
          ></IonInput>
        </IonItem>
        <IonButton
          className="upload-button"
          onClick={handleCreateCategory}
          disabled={!!!text}
          strong
        >
          Save
        </IonButton>
      </div>
    );
};

export const catListBuilder = (categories: Category[], tiles?: Tile[]) => {
  const catList: { id: string; tiles: Tile[] }[] = categories.map(
    (category) => {
      const id = category.id;
      const retTiles: Tile[] = [];

      category.tiles.forEach((tileId) => {
        const tile = tiles?.find((tile) => tile.id === tileId);
        if (tile) retTiles.push(tile);
      });

      return { id, tiles: retTiles };
    }
  );
  return catList;
};

// Tile Builder
const TileBuilder: React.FC<TileBuilderProps> = ({
  tileState,
  name,
  tiles,
  categories,
  tileData,
  onDismiss,
}) => {
  const [user] = useAuthState(auth);

  const [createTile] = useCreateTileMutation();
  const [updateTile] = useUpdateTileMutation();
  const [createFit] = useCreateFitMutation();
  const [updateFit] = useUpdateFitFunctionsMutation();

  const [presentLoading, dismissLoading] = useIonLoading();

  // Hooks
  const {
    photos,
    uploadedPhotos,
    softUploadedPhotos,
    softUploadPhotos,
    uploadPhotos,
    takePhoto,
    setPhotos,
    setUploadedPhotos,
  } = usePhotoGallery(tileState?.image);

  // States
  const [text, setText] = useState<string>(tileState?.tileName ?? "");
  const [desc, setDesc] = useState<string>(tileState?.description ?? "");
  const [price] = useState<number>(tileState?.price ?? 0);
  const [quantity] = useState<number>(tileState?.quantity ?? 0);
  const [isAvailable] = useState<boolean>(tileState?.isAvailable ?? false);
  const [isPublic, setIsPublic] = useState<boolean>(
    tileState?.isPublic ?? false
  );
  const [brand, setBrand] = useState<string>(tileState?.brand ?? "");
  const [size, setSize] = useState<string>(tileState?.size ?? "");
  const [material, setMaterial] = useState<string>(tileState?.material ?? "");
  const [condition, setCondition] = useState<string>(
    tileState?.condition ?? ""
  );
  const [color, setColor] = useState<string>(tileState?.color ?? "");
  const [pattern, setPattern] = useState<string>(tileState?.pattern ?? "");
  const [measurements, setMeasurements] = useState<string>(
    tileState?.measurements ?? ""
  );

  // Placing anchors on the image state
  const [anchoringImage, setAnchoringImage] = useState<TilePhoto | UserPhoto>();

  // Type of fit derived from the name prop.
  const [fitType] = useState<string>(name ?? "tile");

  // disable buttons state
  const [disabled, setDisabled] = useState<boolean>(false);

  const [catTiles, setCatTiles] = useState<{ id: string; tiles: Tile[] }[]>([]);

  // Selected categories for the tile
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    tileState?.category
  );
  const [deleteTile, deleteTileLoading] = useDeleteTileMutation();
  const [deleteFit, deleteFitLoading] = useDeleteFitMutation();

  const { data: userTiles, refetch: queryUserTiles } = useQueryUserTilesQuery({
    currentUser: user,
  });

  // Modal for anchors
  const [present, dismiss] = useIonModal(AnchorModal, {
    onDismiss: (data: any, role: string) => dismiss(data, role),
    photo: isTilePhoto(anchoringImage) ? anchoringImage : undefined,
    catTiles: catTiles,
    userPhoto: isTilePhoto(anchoringImage) ? undefined : anchoringImage,
  });

  function openModal(photo: TilePhoto | UserPhoto, index: number) {
    setAnchoringImage(photo);

    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        // if photo type is TilePhoto, update the photo in the state
        if (isTilePhoto(ev.detail.data)) {
          const newPhotos = [...uploadedPhotos];
          newPhotos[index] = ev.detail.data;
          setUploadedPhotos(newPhotos);
        } else {
          const newPhotos = [...photos];
          newPhotos[index] = ev.detail.data;
          setPhotos(newPhotos);
        }
      },
    });
  }

  function handleDelete(tile: Tile) {
    setDisabled(true);
    presentLoading({
      message: "Deleting...",
    });

    if (name !== "fits") {
      deleteTile({ tileId: tile.id })
        .then((res) => {
          dismissLoading();
          onDismiss();
        })
        .catch((err) => console.log(err));
    } else {
      deleteFit({ fitId: tile.id })
        .then((res) => {
          dismissLoading();
          onDismiss();
        })
        .catch((err) => console.log(err));
    }
  }

  const handleSave = () => {
    setDisabled(true);
    presentLoading({
      message: "Saving...",
    });

    if (tileState?.id) {
      softUploadPhotos();
    } else {
      uploadPhotos();
    }
  };

  // This is the function that handles the creation of the tile collection after uploading to the cloud storage.
  useEffect(() => {
    if (uploadedPhotos?.length > 0 && !tileState?.id) {
      const collection = tileData?.collectionName;

      if (name === "tile") {
        createTile({
          tile: {
            tileName: text,
            description: desc,
            price: price,
            quantity: quantity,
            isAvailable: isAvailable,
            isPublic: isPublic,
            image: uploadedPhotos,
            type: fitType,
            category: selectedCategory,
            collection: collection,
            // Built out tile definition
            brand: brand,
            artist: user?.displayName,
            size: size,
            condition: condition,
            color: color,
            pattern: pattern,
            material: material,
            measurements: measurements,
          } as Partial<Tile>,
        })
          .then((res) => {
            dismissLoading();
            onDismiss();
          })
          .catch((err) => {
            console.log(err);
            dismissLoading();
          });
      } else {
        createFit({
          fit: {
            tileName: text,
            description: desc,
            price: price,
            quantity: quantity,
            isAvailable: isAvailable,
            isPublic: isPublic,
            artist: user?.displayName,
            type: fitType,
            image: uploadedPhotos,
          } as Partial<Tile>,
        })
          .then((res) => {
            dismissLoading();
            onDismiss();
          })
          .catch((err) => {
            console.log(err);
            dismissLoading();
          });
      }
    }
  }, [uploadedPhotos]);

  useEffect(() => {
    if (softUploadedPhotos?.length > 0 && tileState) {
      const collection = tileData?.collectionName;
      const comboPhotos = softUploadedPhotos
        ?.map((photo) => {
          return {
            photo: photo?.photo ?? "",
            anchors: [...(photo?.anchors ?? [])],
          } as TilePhoto;
        })
        .concat(uploadedPhotos);

      if (name === "tile") {
        updateTile({
          tile: {
            id: tileState.id,
            tileName: text,
            description: desc,
            price: price,
            quantity: quantity,
            isAvailable: isAvailable,
            isPublic: isPublic,
            type: fitType,
            category: selectedCategory,
            brand: brand,
            artist: user?.displayName,
            size: size,
            condition: condition,
            color: color,
            pattern: pattern,
            material: material,
            measurements: measurements,
            image: comboPhotos.filter(
              (photo) => photo && !photo?.photo?.includes("blob")
            ),
          } as Partial<Tile>,
        })
          .then((res) => {
            dismissLoading();
            onDismiss();
          })
          .catch((err) => {
            console.log(err);
            dismissLoading();
          });
      } else {
        updateFit({
          fit: {
            id: tileState.id,
            tileName: text,
            description: desc,
            price: price,
            quantity: quantity,
            isAvailable: isAvailable,
            type: fitType,
            isPublic: isPublic,
            artist: user?.displayName ?? "",
            image: comboPhotos.filter(
              (photo) => photo && !photo?.photo?.includes("blob")
            ),
          },
        })
          .then((res) => {
            dismissLoading();
            onDismiss();
          })
          .catch((err) => {
            console.log(err);
            dismissLoading();
          });
      }
    }
  }, [softUploadedPhotos]);

  // build catListBuilder on initial load
  useEffect(() => {
    if (categories?.length) {
      setCatTiles(catListBuilder(categories, userTiles));
    }
  }, [categories, userTiles]);

  useEffect(() => {
    queryUserTiles();
  }, []);

  if (name === "tile")
    return (
      <div className="creator">
        <Swiper
          className="creator-swiper above-everything"
          modules={[Scrollbar, Pagination, IonicSlides]}
          scrollbar={{ draggable: true, hide: false, snapOnRelease: true }}
          pagination={{
            clickable: true,
          }}
          slidesPerView={1}
        >
          {photos?.map((photo, index) => {
            return (
              <SwiperSlide key={index} className="above-everything">
                <IonImg
                  className="modal-image__img above-everything"
                  class="uploaded-image"
                  src={photo?.webviewPath}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <IonButton className="upload-button" onClick={takePhoto}>
          Add Image
        </IonButton>
        {/* subtext for required disclaimer */}
        <IonText className="subtext" color={"medium"}>
          <sub>* Required information</sub>
        </IonText>

        <div className="input-wrapper">
          <IonItem className="create-input">
            <IonLabel position="stacked">Name *</IonLabel>
            <IonInput
              value={text}
              onIonInput={(e: any) => {
                setText(e.target.value);
              }}
            ></IonInput>
          </IonItem>
          <IonItem className="create-input">
            <IonLabel position="stacked">Description *</IonLabel>
            <IonTextarea
              value={desc}
              onIonInput={(e: any) => {
                setDesc(e.target.value);
              }}
              autoGrow={true}
            ></IonTextarea>
          </IonItem>
          <IonItem className="create-input">
            <IonLabel position="stacked">Category *</IonLabel>
            <IonSelect
              value={selectedCategory}
              onIonChange={(e) => {
                setSelectedCategory(e.detail.value);
              }}
            >
              {categories?.map((category, index) => {
                if (category.id !== "All")
                  return (
                    <IonSelectOption key={index} value={category.id}>
                      {category.id}
                    </IonSelectOption>
                  );
              })}
            </IonSelect>
          </IonItem>
          <IonItem className="create-input" disabled={!(photos.length > 0)}>
            <IonLabel position="stacked">Is Public</IonLabel>
            <IonCheckbox
              value={isPublic}
              checked={isPublic}
              onIonChange={(e: any) => {
                setIsPublic(e.detail.checked);
              }}
            ></IonCheckbox>
          </IonItem>
          <IonAccordionGroup className="advanced-details">
            <IonAccordion value="first">
              <IonItem slot="header">
                <IonLabel color={"medium"}>Advanced details</IonLabel>
              </IonItem>
              <div slot="content">
                <IonItem className="create-input">
                  <IonLabel position="stacked">Condition</IonLabel>
                  <IonSelect
                    value={condition}
                    onIonChange={(e) => {
                      setCondition(e.detail.value);
                    }}
                  >
                    {conditions?.map((condition, index) => {
                      return (
                        <IonSelectOption key={index} value={condition}>
                          {condition}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
                <IonItem className="create-input">
                  <IonLabel position="stacked">Size</IonLabel>
                  <IonSelect
                    value={size}
                    onIonChange={(e) => {
                      setSize(e.detail.value);
                    }}
                  >
                    {sizes?.map((size, index) => {
                      return (
                        <IonSelectOption key={index} value={size}>
                          {size}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Brand</IonLabel>
                  <IonInput
                    value={brand}
                    onIonInput={(e: any) => {
                      setBrand(e.target.value);
                    }}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Color</IonLabel>
                  <IonSelect
                    value={color}
                    onIonChange={(e) => {
                      setColor(e.detail.value);
                    }}
                  >
                    {colors?.map((color, index) => {
                      return (
                        <IonSelectOption key={index} value={color}>
                          {color}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Pattern</IonLabel>
                  <IonSelect
                    value={pattern}
                    onIonChange={(e) => {
                      setPattern(e.detail.value);
                    }}
                  >
                    {patterns?.map((pattern, index) => {
                      return (
                        <IonSelectOption key={index} value={pattern}>
                          {pattern}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Material</IonLabel>
                  <IonSelect
                    value={material}
                    onIonChange={(e) => {
                      setMaterial(e.detail.value);
                    }}
                  >
                    {materials?.map((matterial, index) => {
                      return (
                        <IonSelectOption key={index} value={matterial}>
                          {matterial}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Measurements</IonLabel>
                  <IonInput
                    value={measurements}
                    onIonInput={(e: any) => {
                      setMeasurements(e.target.value);
                    }}
                  ></IonInput>
                </IonItem>
              </div>
            </IonAccordion>
          </IonAccordionGroup>

          {/* <IonItem>
        <IonLabel position="stacked">Price</IonLabel>
        <IonInput
          type="number"
          value={price}
          onIonInput={(e: any) => {
            setPrice(parseInt(e.target.value));
          }}
        ></IonInput>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Quantity</IonLabel>
        <IonInput
          type="number"
          value={quantity}
          onIonInput={(e: any) => {
            const value = parseInt(e.target.value);
            if (!value) {
              setIsAvailable(false);
              setIsPublic(false);
            }
            setQuantity(value);
          }}
        ></IonInput>
      </IonItem> */}

          {/* TODO: re-add quantity requirement */}
        </div>
        {/* TODO: Re-implement when marketplace is ready */}
        {/* <IonItem disabled={!isPublic || !quantity}>
        <IonLabel position="stacked">Is Available</IonLabel>
        <IonCheckbox
          value={isAvailable}
          checked={isAvailable}
          onIonChange={(e: any) => {
            setIsAvailable(e.detail.checked);
          }}
        ></IonCheckbox>
      </IonItem> */}

        <IonButton
          className="upload-button"
          onClick={handleSave}
          disabled={
            !!!text || disabled || !photos.length || !desc || !selectedCategory
          }
          strong
        >
          Save
        </IonButton>
        {tileState?.id && (
          <IonButton
            className="upload-button"
            onClick={() => handleDelete(tileState)}
            disabled={disabled}
            strong
          >
            Delete
          </IonButton>
        )}
      </div>
    );
  else
    return (
      <div className="creator">
        <Swiper
          className="creator-swiper above-everything"
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {photos?.map((photo, index) => {
            if (photo?.anchors) {
              return (
                <SwiperSlide key={index}>
                  <IonImg
                    className="modal-image"
                    class="uploaded-image"
                    src={
                      photo?.webviewPath ??
                      "https://dummyimage.com/600x400/b0b0b0/000000&text=No+Image+Available"
                    }
                  ></IonImg>
                  {photo?.anchors?.map((anchor, index) => {
                    return <ImageAnchor key={index} anchor={anchor} />;
                  })}
                  <IonFab vertical="bottom" horizontal="start" slot="fixed">
                    <IonFabButton
                      disabled={disabled}
                      onClick={() => openModal(photo, index)}
                    >
                      <IonIcon icon={pricetagsOutline} />
                    </IonFabButton>
                  </IonFab>
                </SwiperSlide>
              );
            }
            return (
              <SwiperSlide key={index}>
                <IonImg
                  className="modal-image__img"
                  class="uploaded-image"
                  src={photo?.webviewPath}
                />

                <IonFab vertical="bottom" horizontal="start" slot="fixed">
                  <IonFabButton onClick={() => openModal(photo, index)}>
                    <IonIcon icon={pricetagsOutline} />
                  </IonFabButton>
                </IonFab>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <IonButton className="upload-button" onClick={takePhoto}>
          Add Image
        </IonButton>
        <IonText className="subtext" color={"medium"}>
          <sub>* Required information</sub>
        </IonText>
        <IonItem>
          <IonLabel position="stacked">Name *</IonLabel>
          <IonInput
            value={text}
            onIonInput={(e: any) => {
              setText(e.target.value);
            }}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Description</IonLabel>
          <IonTextarea
            value={desc}
            onIonInput={(e: any) => {
              setDesc(e.target.value);
            }}
            autoGrow={true}
          ></IonTextarea>
        </IonItem>
        {/* <IonItem>
        <IonLabel position="stacked">Price</IonLabel>
        <IonInput
          type="number"
          value={price}
          onIonInput={(e: any) => {
            setPrice(parseInt(e.target.value));
          }}
        ></IonInput>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Quantity</IonLabel>
        <IonInput
          type="number"
          value={quantity}
          onIonInput={(e: any) => {
            const value = parseInt(e.target.value);
            if (!value) {
              setIsAvailable(false);
              setIsPublic(false);
            }
            setQuantity(value);
          }}
        ></IonInput>
      </IonItem> */}

        {/* TODO: re-add quantity requirement */}
        <IonItem disabled={!(photos.length > 0)}>
          <IonLabel position="stacked">Is Public</IonLabel>
          <IonCheckbox
            value={isPublic}
            checked={isPublic}
            onIonChange={(e: any) => {
              setIsPublic(e.detail.checked);
            }}
          ></IonCheckbox>
        </IonItem>
        {/* TODO: Re-implement when marketplace is ready */}
        {/* <IonItem disabled={!isPublic || !quantity}>
        <IonLabel position="stacked">Is Available</IonLabel>
        <IonCheckbox
          value={isAvailable}
          checked={isAvailable}
          onIonChange={(e: any) => {
            setIsAvailable(e.detail.checked);
          }}
        ></IonCheckbox>
      </IonItem> */}

        <IonButton
          className="upload-button"
          onClick={handleSave}
          disabled={!!!text || disabled || !photos.length}
          strong
        >
          Save
        </IonButton>
        {tileState?.id && (
          <IonButton
            className="upload-button"
            onClick={() => handleDelete(tileState)}
            disabled={disabled}
            strong
          >
            Delete
          </IonButton>
        )}
      </div>
    );
};

// Uses name to determine which component to render, and uses tileData to determine cat/collection.
const CreateModal: React.FC<Props> = ({
  onDismiss,
  tileState,
  categoryState,
  tiles,
  categories,
  tileData,
  name,
}) => {
  const inputRef = useRef<HTMLIonInputElement>(null);
  if (name === "tile") {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color="medium"
                onClick={() => onDismiss(null, "cancel")}
              >
                <IonIcon slot="start" ios={closeOutline} md={closeSharp} />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {tileState?.tileName
                ? "Edit tile " + tileState?.tileName
                : "Create a tile"}
            </IonTitle>

            <IonButtons></IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent slot="fixed" className="ion-padding">
          <TileBuilder
            onDismiss={onDismiss}
            tileState={tileState}
            tiles={tiles}
            categories={categories}
            name={name}
            tileData={tileData}
          />
        </IonContent>
      </IonPage>
    );
  }

  // TODO: Add category builder
  //else if (name === "category") {
  //   return (
  //     <IonPage>
  //       <IonHeader>
  //         <IonToolbar>
  //           <IonButtons slot="start">
  //             <IonButton
  //               color="medium"
  //               onClick={() => onDismiss(null, "cancel")}
  //             >
  //               <IonIcon slot="start" ios={closeOutline} md={closeSharp} />
  //             </IonButton>
  //           </IonButtons>
  //           <IonTitle>
  //             {categoryState?.id
  //               ? "Edit category " + categoryState?.id
  //               : "Create a category"}
  //           </IonTitle>

  //           <IonButtons ></IonButtons>
  //         </IonToolbar>
  //       </IonHeader>
  //       <IonContent slot="fixed" className="ion-padding">
  //         <IonGrid>
  //           <IonCol>
  //             {categoryState?.id ? (
  //               <div className="modal-container">
  //                 <IonImg
  //                   className="modal-image"
  //                   src={
  //                     categoryState?.image[0] ??
  //                     "https://dummyimage.com/600x400/b0b0b0/000000&text=No+Image+Available"
  //                   }
  //                 />
  //               </IonContent>
  //             ) : (
  //               <CollectionBuilder
  //                 onDismiss={onDismiss}
  //                 categoryState={categoryState}
  //                 name={name}
  //               />
  //             )}
  //           </IonCol>
  //         </IonGrid>
  //       </IonContent>
  //     </IonPage>
  //   );
  // } // if (name === "fits")
  else
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color="medium"
                onClick={() => onDismiss(null, "cancel")}
              >
                <IonIcon slot="start" ios={closeOutline} md={closeSharp} />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {tileState?.tileName
                ? "Edit fit " + tileState?.tileName
                : "Create a fit"}
            </IonTitle>

            <IonButtons></IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent slot="fixed" className="ion-padding">
          <TileBuilder
            onDismiss={onDismiss}
            tileState={tileState}
            tiles={tiles}
            categories={categories}
            name={name}
            tileData={tileData}
          />
        </IonContent>
      </IonPage>
    );
};

export default CreateModal;
