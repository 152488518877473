import { useEffect, useState } from "react";

import { FirebaseOptions, initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import "firebase/compat/auth";
import {
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  updateProfile,
  User,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  useIonLoading,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import {
  personAddOutline,
  personAddSharp,
  personCircleOutline,
  personCircleSharp,
} from "ionicons/icons";
import "./firebase.scss";
import {
  LoginModal,
  RegisterModal,
} from "./components/Modals/AuthModals/AuthModals";
import { usePhotoGallery } from "./hooks/usePhotoGallery";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
const customParameters: FirebaseOptions = {
  apiKey: "AIzaSyBcSERUrIIl_Dq4Cyjq9LgoQJyP7c683wc",
  authDomain: "fitzy-57120.firebaseapp.com",
  projectId: "fitzy-57120",
  storageBucket: "fitzy-57120.appspot.com",
  messagingSenderId: "647470244647",
  appId: "1:647470244647:web:e27f62f41d222e2226bf02",
  measurementId: "G-2S4T86BZG5",
};

// const customParameters: FirebaseOptions = {
//   apiKey: "AIzaSyBcSERUrIIl_Dq4Cyjq9LgoQJyP7c683wc",
//   authDomain: "fitzy-57120.firebaseapp.com",
//   storageBucket: "fitzy-57120.appspot.com",
//   projectId: "fitzy-57120",
//   messagingSenderId: "647470244647",
//   appId: "1:647470244647:web:e27f62f41d222e2226bf02",
//   measurementId: "G-2S4T86BZG5",
// };

// Initialize Firebase
// const app = firebase.initializeApp(customParameters);
// export const auth = getAuth(app);
// export const db = getFirestore(app);
// export const functions = getFunctions(app);

const app = initializeApp(customParameters);

export const functions = getFunctions(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
export const db = getFirestore(app);

// connectAuthEmulator(auth, "http://localhost:9099");
// connectFunctionsEmulator(functions, "localhost", 5001);
// connectFirestoreEmulator(db, "localhost", 8080);
// connectStorageEmulator(storage, "localhost", 9199);

export const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult: any, redirectUrl: any) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true;
    },
  },
  signInOptions: [
    // List of OAuth providers supported.
    GoogleAuthProvider.PROVIDER_ID,
  ],
};

// placeholder avatar
export const defaultAvatar = (
  <IonIcon
    className="user-avatar profile-image"
    slot="start"
    size="large"
    ios={personCircleOutline}
    md={personCircleSharp}
  />
);

export const defaultAvatarAdd = (
  <IonIcon
    className="user-avatar profile-image"
    slot="start"
    size="large"
    ios={personAddOutline}
    md={personAddSharp}
  />
);

export const AvatarElement = (photo: string) => (
  <IonAvatar className="profile-image">
    <img src={photo} referrerPolicy="no-referrer" />
  </IonAvatar>
);

// signin props
interface SignInProps {
  userChangeCB: (avatar: string) => void;
}

const SignInScreen: React.FC<SignInProps> = ({ userChangeCB }) => {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const [avatarElement, setPhotoURL] = useState(defaultAvatar); // Firebase user photo URL.
  const [user, userLoading, error] = useAuthState(auth, {
    onUserChanged: (user) => userChange(user),
  });
  const [signOut, loading] = useSignOut(auth);
  const { uploadPhotos, uploadedPhotos, takePhoto, photos } = usePhotoGallery();

  const [present] = useIonToast();
  const [presentSecondToast] = useIonToast();

  const [presentLoginModal, setPresentLoginModal] = useIonModal(LoginModal, {
    onDismiss: () => setPresentLoginModal(false),
  });

  const [presentRegisterModal, setRegisterModal] = useIonModal(RegisterModal, {
    onDismiss: () => setRegisterModal(false),
    setAvatar: setPhotoURL,
  });

  // Present login modal if user is not signed in.
  useEffect(() => {
    setTimeout(() => {
      if (!user) {
        presentLoginModal();
      }
    }, 2000);
  }, []);

  // Set photo url to default if no photo url is provided
  const userChange = async (userState: User | null) => {
    if (userState?.photoURL) {
      const avatar = AvatarElement(userState.photoURL);
      setPhotoURL(avatar);
    } else {
      setPhotoURL(defaultAvatarAdd);
    }
    if (userState && !userState?.emailVerified) {
      present({
        message: "Verify your account.",
        duration: 5000,
        color: "warning",

        buttons: [
          {
            text: "Resend",
            handler: () => {
              sendEmailVerification(userState).then(() => {
                presentSecondToast({
                  message: "Verification email sent",
                  duration: 3000,
                  color: "success",
                });
              });
            },
          },
          {
            text: "Dismiss",
            role: "cancel",
          },
        ],
      });
    }
  };

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(
      async (user: any) => {
        setIsSignedIn(!!user);
      }
    );
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  });

  const updateProfileImage = async (photo: string) => {
    if (auth?.currentUser) {
      updateProfile(auth?.currentUser, { photoURL: photo }).then(() => {
        const avatar = AvatarElement(photo);
        userChangeCB(photo);
        setPhotoURL(avatar);
      });
    }
  };

  const changeProfilePictureHandler = async () => {
    takePhoto();
  };

  useEffect(() => {
    if (photos.length > 0) {
      uploadPhotos()
        .then((uploadedPhotos) => {
          if (uploadedPhotos?.length) {
            const uploadedPhoto = uploadedPhotos[0];
            updateProfileImage(uploadedPhoto.photo);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [photos]);

  if (!isSignedIn) {
    return (
      <IonList>
        <IonIcon
          className="user-avatar profile-image"
          style={{ cursor: "default" }}
          slot="start"
          size="large"
          ios={personCircleOutline}
          md={personCircleSharp}
        />

        {/* Sign in button */}
        <IonButton
          className="anonymous"
          expand="block"
          fill="outline"
          color="primary"
          onClick={() => {
            presentLoginModal();
          }}
        >
          <IonLabel>Sign in</IonLabel>
        </IonButton>
        <IonButton
          expand="block"
          fill="outline"
          color="primary"
          onClick={() => {
            presentRegisterModal();
          }}
        >
          <IonLabel>Register</IonLabel>
        </IonButton>
      </IonList>
    );
  }
  return (
    <div className="menu-profile">
      <div
        onClick={() => {
          changeProfilePictureHandler();
        }}
        style={{ cursor: "pointer" }}
      >
        {avatarElement}
      </div>
      <IonListHeader className="display-name">
        {user?.displayName ?? user?.email}
      </IonListHeader>
      <IonNote>{user?.email}</IonNote>
      <IonNote>
        <a onClick={() => signOut()}>Sign-out</a>
      </IonNote>
    </div>
  );
};

export default SignInScreen;
