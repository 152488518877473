import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import Menu from "./components/Menu";
import Page from "./pages/Page";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { createContext, useState } from "react";

import Dashboard from "./pages/Dashboard/Dashboard";
import { Category } from "./types/tiles";
import Favorites from "./pages/Favorites/Favorites";
import { useQueryCategoriesQuery } from "./store/api/categoriesApi";

setupIonicReact();
export const CategoriesContext = createContext<Category[]>([]);
export const AvatarContext = createContext<string>("");

const App: React.FC = () => {
  const [user, loading] = useAuthState(auth);
  const { data = [], refetch } = useQueryCategoriesQuery({ currentUser: user });

  const [userAvatar, setUserAvatar] = useState<string>("");

  const refreshMenu = () => {
    refetch();
  };

  // user change callback
  const userChange = (avatar: string) => {
    if (avatar) {
      setUserAvatar(avatar);
    }
  };

  return (
    <CategoriesContext.Provider value={data}>
      <AvatarContext.Provider value={userAvatar}>
        <IonApp>
          <IonReactRouter>
            <IonSplitPane contentId="main">
              <Menu loading={loading} userChangeCB={userChange} />
              <IonRouterOutlet id="main">
                <Redirect exact from="/" to="/home" />
                <Route path="/home" exact={true}>
                  <Dashboard loading={loading} />
                </Route>
                <Route path="/wardrobe" exact={true}>
                  <Page loading={loading} refreshMenu={refreshMenu} />
                </Route>
                <Route path="/fits" exact={true}>
                  <Page loading={loading} refreshMenu={refreshMenu} />
                </Route>
                <Route path="/favorites" exact={true}>
                  <Favorites loading={loading} />
                </Route>
                <Route component={Dashboard} />
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
      </AvatarContext.Provider>
    </CategoriesContext.Provider>
  );
};

export default App;
