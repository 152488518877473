import { Tile } from "../../types/tiles";
import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  updateDoc,
  getDoc,
  setDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import { db, functions } from "../../firebase";
import { User } from "firebase/auth";
import { baseApi } from "./baseApi";
import { httpsCallable } from "firebase/functions";

export async function createTile(tileData: Partial<Tile>) {
  const callable = httpsCallable(functions, "createTile");
  return callable(tileData);
}

export async function updateTile(tileData: Partial<Tile>) {
  const callable = httpsCallable(functions, "updateTile");
  return callable(tileData);
}

export async function deleteTile(tileId: string) {
  const callable = httpsCallable(functions, "deleteTile");
  return callable({ tileId });
}

export async function createFit(fitData: Partial<Tile>) {
  const callable = httpsCallable(functions, "createFit");
  return callable(fitData);
}

export async function updateFit(fitData: Partial<Tile>) {
  const callable = httpsCallable(functions, "updatefit");
  return callable(fitData);
}

export async function deleteFit(fitId: string) {
  const callable = httpsCallable(functions, "deletefit");
  return callable({ fitId });
}

export const tilesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    queryUserTiles: builder.query<Tile[], { currentUser?: User | null }>({
      async queryFn({ currentUser }) {
        try {
          if (currentUser && currentUser?.uid) {
            const userTilesRef = query(
              collection(db, "users", currentUser?.uid, "tiles"),
              orderBy("createdAt", "desc")
            );
            const userTilesSnapshot = await getDocs(userTilesRef);

            const userTilesData = userTilesSnapshot.docs.map((doc) => {
              const data = doc.data();
              const id = doc.id;
              return { id, ...data } as Tile;
            });

            if (userTilesData?.length) {
              return { data: userTilesData };
            } else {
              return { data: [] };
            }
          } else {
            return { error: "User not found" };
          }
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      providesTags: ["UserTiles"],
    }),
    queryUserFits: builder.query<Tile[], { currentUser?: User | null }>({
      async queryFn({ currentUser }) {
        try {
          if (currentUser && currentUser?.uid) {
            const userFitsRef = query(
              collection(db, "users", currentUser?.uid, "fits"),
              orderBy("createdAt", "desc")
            );
            const userFitsSnapshot = await getDocs(userFitsRef);

            const userFitsData = userFitsSnapshot.docs.map((doc) => {
              const data = doc.data();
              const id = doc.id;
              return { id, ...data } as Tile;
            });

            if (userFitsData?.length) {
              return { data: userFitsData };
            } else {
              return { data: [] };
            }
          } else {
            return { error: "User not found" };
          }
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      providesTags: ["UserFits"],
    }),
    queryFavoriteTiles: builder.query<Tile[], { currentUser?: User | null }>({
      async queryFn({ currentUser }) {
        try {
          if (currentUser && currentUser?.uid) {
            const userRef = doc(db, "users", currentUser?.uid);

            const favoriteTilesSnapshot = await getDoc(userRef);
            let favoriteTilesData = favoriteTilesSnapshot.data()?.favorites;
            const publicTilesRef = collection(db, "public");
            if (favoriteTilesData?.length) {
              const chunks = [];
              const chunkSize = 10;
              for (let i = 0; i < favoriteTilesData.length; i += chunkSize) {
                chunks.push(favoriteTilesData.slice(i, i + chunkSize));
              }

              const publicTilesData = await Promise.all(
                chunks.map(async (chunk) => {
                  const tilesDocsRefs = chunk.map((id: string | undefined) =>
                    doc(publicTilesRef, id)
                  );
                  const tileDocs = await getDocs(
                    query(
                      publicTilesRef,
                      where("__name__", "in", tilesDocsRefs)
                    )
                  );

                  const tileData = tileDocs.docs.map((tileDoc) => {
                    const data = tileDoc.data();

                    return {
                      ...data,
                      id: tileDoc.id,
                      isFavorite: true,
                    } as Tile;
                  });

                  // Update favoriteTilesData by filtering out non-existing documents
                  favoriteTilesData = favoriteTilesData.filter((id: string) =>
                    tileDocs.docs.some((tileDoc) => tileDoc.id === id)
                  );

                  return tileData;
                })
              );

              // Update the user's favorites list with the filtered favoriteTilesData
              await updateDoc(userRef, { favorites: favoriteTilesData });

              const flattenedPublicTilesData = publicTilesData.flat();

              return { data: flattenedPublicTilesData };
            } else {
              return { data: [] };
            }
          } else {
            return { error: "User not found" };
          }
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      providesTags: ["FavoriteTiles"],
    }),

    // mutations
    updateFit: builder.mutation<Tile, { currentUser?: User | null; fit: Tile }>(
      {
        async queryFn({ currentUser, fit }) {
          try {
            if (currentUser && currentUser?.uid) {
              const userFitsRef = doc(
                db,
                "users",
                currentUser?.uid,
                "fits",
                fit.id
              );
              await updateDoc(userFitsRef, fit as any);
              const updatedFit = await getDoc(userFitsRef);
              const updatedFitData = updatedFit.data();
              const updatedFitId = updatedFit.id;
              const updatedFitWithId = {
                id: updatedFitId,
                ...updatedFitData,
              } as Tile;

              //   TODO: Make sure this doesn't break anything!
              //   const updatedUserFits = userFits.map((userFit) => {
              //     if (userFit.id === updatedFitId) {
              //       return updatedFitWithId;
              //     } else {
              //       return userFit;
              //     }
              //   });

              //   setUserFits(updatedUserFits);

              if (updatedFitData?.isPublic) {
                const publicFitsRef = doc(db, "public", fit.id);
                await setDoc(publicFitsRef, updatedFitData, { merge: true });
              } else if (!updatedFitData?.public) {
                const publicFitsRef = doc(db, "public", fit.id);
                await deleteDoc(publicFitsRef);
              }

              return { data: updatedFitWithId };
            } else {
              return { error: "User not found" };
            }
          } catch (error: any) {
            console.error(error.message);
            return { error: error.message };
          }
        },
        invalidatesTags: ["UserFits", "Tiles"],
      }
    ),
    addFavorite: builder.mutation<
      any,
      { currentUser?: User | null; tileId: string }
    >({
      async queryFn({ currentUser, tileId }) {
        try {
          if (currentUser && currentUser?.uid) {
            const userRef = doc(db, "users", currentUser?.uid);
            const userDoc = await getDoc(userRef);
            const userData = userDoc.data();

            // add to favorites
            const updatedFavorites = [
              ...(userData?.favorites || []),
              tileId,
            ].filter((value, index, self) => {
              return self.indexOf(value) === index;
            });

            await updateDoc(userRef, {
              favorites: updatedFavorites,
            });

            return { data: true };
          } else {
            return { error: "User not found" };
          }
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      invalidatesTags: ["FavoriteTiles", "Favorites"],
    }),
    removeFavorite: builder.mutation<
      any,
      { currentUser?: User | null; tileId: string }
    >({
      async queryFn({ currentUser, tileId }) {
        try {
          if (currentUser && currentUser?.uid) {
            const userRef = doc(db, "users", currentUser?.uid);

            const userDoc = await getDoc(userRef);
            const userData = userDoc.data();

            const updatedFavorites = userData?.favorites.filter(
              (favorite: string) => favorite !== tileId
            );

            await updateDoc(userRef, { favorites: updatedFavorites });
            return { data: true };
          } else {
            return { error: "User not found" };
          }
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      invalidatesTags: ["FavoriteTiles", "Favorites"],
    }),
    deleteTile: builder.mutation<any, { tileId: string }>({
      async queryFn({ tileId }) {
        try {
          await deleteTile(tileId);
          return { data: true };
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      invalidatesTags: ["Tiles", "UserTiles"],
    }),
    deleteFit: builder.mutation<any, { fitId: string }>({
      async queryFn({ fitId }) {
        try {
          await deleteFit(fitId);
          return { data: true };
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      invalidatesTags: ["Favorites", "FavoriteTiles", "UserFits", "Tiles"],
    }),
    createTile: builder.mutation<any, { tile: Partial<Tile> }>({
      async queryFn({ tile }) {
        try {
          await createTile(tile);
          return { data: true };
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      invalidatesTags: ["Tiles", "UserTiles"],
    }),
    createFit: builder.mutation<any, { fit: Partial<Tile> }>({
      async queryFn({ fit }) {
        try {
          await createFit(fit);
          return { data: true };
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      invalidatesTags: ["Favorites", "FavoriteTiles", "UserFits", "Tiles"],
    }),
    updateTile: builder.mutation<any, { tile: Partial<Tile> }>({
      async queryFn({ tile }) {
        try {
          await updateTile(tile);
          return { data: true };
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      invalidatesTags: ["Tiles", "UserTiles"],
    }),
    updateFitFunctions: builder.mutation<any, { fit: Partial<Tile> }>({
      async queryFn({ fit }) {
        try {
          await updateFit(fit);
          return { data: true };
        } catch (error: any) {
          console.error(error.message);
          return { error: error.message };
        }
      },
      invalidatesTags: ["Favorites", "FavoriteTiles", "UserFits", "Tiles"],
    }),
  }),
});
export const {
  useQueryUserTilesQuery,
  useQueryUserFitsQuery,
  useQueryFavoriteTilesQuery,
  useUpdateFitMutation,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation,
  useDeleteTileMutation,
  useDeleteFitMutation,
  useCreateTileMutation,
  useCreateFitMutation,
  useUpdateTileMutation,
  useUpdateFitFunctionsMutation,
} = tilesApi;
